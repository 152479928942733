import { PickupScheduleComponent } from "@/app/infrastructures/dependencies/PickupScheduleComponent";
import {
  PickupSchedule,
  PickupScheduleList,
  RequestPickupScheduleList
} from "@/domain/entities/PickupSchedule";
import store from "@/store";
import { container } from "tsyringe";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import { PickupSchedulePresenter } from "../presenters/PickupSchedulePresenter";
import { PickupScheduleApiRequest, PickupScheduleFormApiRequest } from "@/data/payload/api/PickupScheduleApiRequest";

interface PickupScheduleState {
  pickupScheduleList: PickupScheduleList;
  pickupScheduleData: PickupSchedule;
  isLoading: boolean;
}
PickupScheduleComponent.init();
@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "pickup-schedule"
})
class PickupScheduleStore extends VuexModule implements PickupScheduleState {
  public pickupScheduleList: PickupScheduleList = new PickupScheduleList();
  public pickupScheduleData: PickupSchedule = new PickupSchedule();
  public isLoading = false;

  @Action
  public getPickupScheduleList(params: RequestPickupScheduleList) {
    const presenter = container.resolve(PickupSchedulePresenter);
    return presenter.getPickupScheduleList(params);
  }

  @Action
  public getDetailPickupSchedule(id: any) {
    const presenter = container.resolve(PickupSchedulePresenter);
    return presenter.getDetailPickupSchedule(id);
  }

  @Action
  public updateStatusPickupSchedule(params: {
    payload: PickupScheduleApiRequest;
    id: any;
  }) {
    const presenter = container.resolve(PickupSchedulePresenter);
    return presenter.updateStatusPickupSchedule(params.payload, params.id);
  }

  @Action
  public getCourierVehicleList(search: string) {
    const presenter = container.resolve(PickupSchedulePresenter);
    return presenter.getCourierVehicleList(search);
  }

  @Action({ rawError: true })
  public createPickupSchedule(payload: PickupScheduleFormApiRequest) {
    const presenter = container.resolve(PickupSchedulePresenter);

    return presenter.createPickupSchedule(payload);
  }

  @Action({ rawError: true })
  public updatePickupSchedule(params: {
    payload: PickupScheduleFormApiRequest;
    id: number;
  }) {
    const presenter = container.resolve(PickupSchedulePresenter);

    return presenter.updatePickupSchedule(params.payload, params.id);
  }

  @Mutation
  public setIsLoading(bool: boolean): void {
    this.isLoading = bool;
  }

  @Mutation
  public setPickupSchedulData(data: PickupSchedule): void {
    this.pickupScheduleData = data;
  }
}

export const PickupScheduleController = getModule(PickupScheduleStore);
