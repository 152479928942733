
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue } from "vue-class-component";
import SectionPackageDetail from "./components/section-package-detail.vue";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import Woodpacking from "../component/woodpacking/index.vue";
import { HeavyWeightSurchargeController } from "@/app/ui/controllers/HeavyWeightSurchargeController";
import SectionAdditionalInfoInterpack from "./components/section-additional-info-interpack.vue";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import SectionFTZ from "./components/section-ftz.vue";
import SectionProductUpselling from "./components/section-product-upselling.vue";
import { flags } from "@/feature-flags";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import ProductPoster from "../component/product-poster/index.vue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import SectionSTTManualReference from "./components/section-stt-manual-reference.vue";
import SectionSenderDetail from "./components/section-sender-detail.vue";
import SectionRecipientDetail from "./components/section-recipient-detail.vue";
import SectionGoodsWeight from "./components/section-goods-weight.vue";
import { CommodityController } from "@/app/ui/controllers/CommodityController";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";
import { ProductConfigurationRuleList } from "@/domain/entities/Product";
import { detailClientManagement, maxKg } from "../booking-utils";
import SectionFooterBooking from "./components/footer-booking/index.vue";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { formatPriceRM, formatPriceRP } from "@/app/infrastructures/misc/Utils";
import { AddBookingCorporate, AddManualBooking } from "@/data/payload/api/BookingRequest";
import DialogPayment from "@/app/ui/components/dialog-payment/index.vue";
import SectionFilterClient from "./components/section-filter-client.vue";
import { DistrictData } from "@/domain/entities/Location";
import debounce from "lodash/debounce";
import { InternationalDocumentController } from "@/app/ui/controllers/InternationalDocumentController";

interface Diskon {
  isDiscount: boolean;
  totalTariff: number;
  totalAfterDiscount: number;
  totalBeforeDiscount: number;
}
@Options({
  components: {
    SectionPackageDetail,
    Woodpacking,
    SectionAdditionalInfoInterpack,
    SectionFTZ,
    SectionProductUpselling,
    ProductPoster,
    SectionSTTManualReference,
    SectionSenderDetail,
    SectionRecipientDetail,
    SectionGoodsWeight,
    SectionFooterBooking,
    DialogPayment,
    SectionFilterClient,
    // call your component here
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess && !this.forceRouter) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      BookingController.setShowModalSuccess(false);
      next();
    }
  },
  watch: {
    "payload.productName": function() {
      this.fetchSlaEstimation();
    },
    "payload.destinationCity": function() {
      this.fetchSlaEstimation();
    },
    "payload.originCity": function() {
      this.fetchSlaEstimation();
    },
    "payload.destinationDistrict": function() {
      this.fetchSlaEstimation();
    },
    "payload.originDistrict": function() {
      this.fetchSlaEstimation();
    },
    totalChargeableWeight() {
      this.onCallProductTotalChWeightPriceChange();
    },
    "payload.commodityCode"() {
      this.onCallProductTotalChWeightPriceChange();
    },
  },
})
export default class CreateBookingV2 extends Vue {
  renderDetailCalculation = true;
  isEditTerminated = false;
  answer = false;
  newPath = "";
  hideKgs = false;

  get isLoading() {
    return MainAppController.loadingModal;
  }

  onGetClientStatus(obj: any) {
    BookingController.setProductName("");
    this.error.errorMandatoryClientColumn =
      !this.payload.clientName &&
      (this.formType === "client" || this.getAccount.accountType === "client");
    if (!obj.status && !obj.isDO) {
      this.pickProductUpselling(this.manualBooking.clientName);
    }

    if (obj.status) {
      this.resetWhenStatusClientTrue();
    } else {
      this.resetWhenStatusClientFalse(obj);
    }

    if (obj) {
      this.codDfodClient(obj.id);
    } else {
      this.typeDeliveryClientCOD = false;
      this.typeDeliveryClientDFOD = false;
      this.setValueDeliveryTypeClient();
    }
  }

  typeDeliveryClientCOD = false;
  typeDeliveryClientDFOD = false;
  async codDfodClient(id: number) {
    await detailClientManagement(id);
    this.typeDeliveryClientCOD =
      ClientManagementController.detailClientManagement.clientIsCod;
    this.typeDeliveryClientDFOD =
      ClientManagementController.detailClientManagement.clientIsDfod;
    this.setValueDeliveryTypeClient();
  }

  setValueDeliveryTypeClient() {
    this.refs = this.$refs;
    this.refs.packageDetail.setStatus(
      this.typeDeliveryClientCOD,
      this.typeDeliveryClientDFOD,
      "non-cod"
    );
  }

  isErrorClient = false;
  isClientDO = false;
  statusCODClient = false;
  isClientCOD = false;
  resetWhenStatusClientTrue() {
    this.isErrorClient = true;
    this.isClientDO = true;
    this.isClientCOD = false;
    this.manualBooking.isCOD = false;
    this.manualBooking.codAmount = 0;
    this.statusCODClient = true;
    this.manualBooking.sttManual = "";
    this.error.sttManual = false;
    this.pickProductUpselling(this.manualBooking.clientName);
  }

  resetWhenStatusClientFalse(obj: any) {
    this.statusCODClient = obj.isDO;
    this.isErrorClient = false;

    if (obj.isDO) {
      this.isClientDO = obj.isDO;
      this.manualBooking.sttManual = "";
      // this.errorCaptionSttManual = "";
      this.error.sttManual = false;
      this.manualBooking.destinationCity = "";
      this.resetProductUpselling();
    } else {
      if (!this.isProductSelected) {
        this.isClientDO = obj.isDO;
        this.manualBooking.sttManual = "";
        // this.errorCaptionSttManual = "";
        this.error.sttManual = false;
      } else {
        this.isClientDO = obj.isDO;
      }
    }

    if (obj.isCOD) {
      this.isClientCOD = true;
    } else {
      this.isClientCOD = false;
      this.manualBooking.isCOD = false;
      this.manualBooking.codAmount = 0;
      // this.errorCaptionSttManual = "";
      this.error.sttManual = false;
    }
  }

  onCallProductTotalChWeightPriceChange() {
    this.hideKgs =
      this.manualBooking.destinationCity?.code &&
      this.manualBooking.commodityCode?.code;
    if (this.hideKgs) {
      this.pickProductUpselling(this.manualBooking.destinationCity);
    }
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
    BookingController.removeWeightDimensions();
    this.resetProductUpselling();
    this.manualBooking.destinationCity = "";
    BookingController.setErrorDestination(false);
    BookingController.setProductName("");
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  formatPrice(value: number) {
    return !AccountController.accountData.accountIsForeign
      ? formatPriceRP(value)
      : formatPriceRM(value);
  }

  onCloseSuccess() {
    this.refs = this.$refs;
    const tempSenders = JSON.parse(
      JSON.stringify({
        customerName: this.payload.sender,
        customerAddress: this.payload.addressSender,
        customerPhoneCode: this.payload.phoneCodeSender,
        customerPhoneflag: this.payload.phoneFlagSender,
        customerPhoneNumber: this.payload.phoneSender,
      })
    );
    this.resetPageCreateBooking();
    this.resetProductUpselling();
    BookingController.removeWeightDimensions();
    BookingController.setWeightDimensions({
      grossWeight: !this.$route.query.weight ? "0" : this.$route.query.weight,
      dimension: !this.$route.query.length
        ? {
            length: 10,
            width: 10,
            height: 10,
          }
        : {
            length: Number(this.$route.query.length),
            width: Number(this.$route.query.width),
            height: Number(this.$route.query.height),
          },
      volumeWeight: 0.16,
      convertVolumeWeight: "0,16",
      errorHws: false,
    });
    this.manualBooking.clientName = "";
    this.manualBooking.isWoodpack = false;
    const renderWoodPacking: any = this.$refs.woodpacking;
    renderWoodPacking.resetData();

    this.refs.senderDetail.onChooseSenders(tempSenders, false);
    this.listKGs = BookingController.weightDimensions.data;
    this.refs.goodsWeight?.calculationVolumeWeight(0);
    this.refs.recipientDetail.clearError();
    this.refs.packageDetail.automaticSetCheckbox(0);
    this.refs.packageDetail.setInsurance();
    this.scrollToTop();
  }

  scrollToTop() {
    const el = document.getElementById("detailLayoutData");
    if (!el) {
      return;
    }
    el.scroll({
      top: 0,
      behavior: "smooth"
    });
  }

  created() {
    window.addEventListener("keydown", this.onEnterBooking);
  }

  unmounted() {
    BookingController.setDefaultManualBooking();
    window.removeEventListener("keydown", this.onEnterBooking);
  }

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  async mounted() {
    await AccountController.checkAccountLocation();
    this.getCustomerList();
    this.getCommodityList();
    this.getClientID();
    BookingController.setDefaultManualBooking();
    BookingController.setForceRouter(false);
    this.resetPageCreateBooking();
    this.manualBooking.phoneCodeSender = this.accountIsNonForeign
      ? "+62"
      : "+60";
    this.manualBooking.phoneFlagSender = this.accountIsNonForeign
      ? "indonesia"
      : "malaysia";
    this.setCodDfodClient();
  }

  resetPageCreateBooking() {
    const tempClient = JSON.parse(
      JSON.stringify(this.manualBooking.clientName)
    );
    this.currentFieldIndex = 0;
    this.resetFormbookingFromController();
    this.resetDestinationAndCommodity();
    this.renderDetailCalculation = false;
    this.manualBooking.clientName = tempClient;
    this.manualBooking.isWoodpack = false;
    this.manualBooking.addressReceiverType = "home";
    this.refs = this.$refs;
    if (this.refs.packageDetail) {
      this.refs.packageDetail.setStatus(false, false, "non-cod");
      this.refs.packageDetail.setInsurance();
    }

    this.$nextTick(() => {
      this.renderDetailCalculation = true;
    });
    this.additionalInfo = {
      commodity: "",
      email: "",
      files: [],
  };
    this.discount = {
      isDiscount: false,
      totalTariff: 0,
      totalAfterDiscount: 0,
      totalBeforeDiscount: 0,
    };
    BookingController.setCOD(false);
    BookingController.setDfod(false);
    // this.fileAdjustments = [];
    HeavyWeightSurchargeController.setMinimumHeavyWeightSurcharge({
      value: 0,
      existMinimum: false,
    });
    this.showWarningHws = false;
  }

  resetFormbookingFromController() {
    BookingController.setShowModalSuccess(false);
    BookingController.setShowModalManualBooking(false);
    BookingController.setDefaultManualBooking();
    BookingController.setError(false);
    BookingController.setEmptyDestination("");
    BookingController.setIncrementManualBooking();
    BookingController.setGuardBookingSubmit(false);
  }

  resetDestinationAndCommodity() {
    const tempProduct = JSON.parse(
      JSON.stringify(this.manualBooking.productName)
    );
    this.onSelectDestination();
    BookingController.setProductName(
      String(
        !this.$route.query.product ? tempProduct : this.$route.query.product
      )
    );
    const destinationParam = {
      code: String(this.$route.query.destinationCode),
      name: String(this.$route.query.destinationName),
      cityCode: String(this.$route.query.destinationCityCode),
      status: String(this.$route.query.destinationStatus),
      isCod: String(this.$route.query.destinationCod),
      type: String(this.$route.query.destinationType),
      vendor_code: String(this.$route.query.destinationVendor),
    };
    BookingController.setDestinationCity(
      !this.$route.query.destinationCode ? "" : destinationParam
    );
    const commodityParam = {
      name: String(this.$route.query.commodityName),
      code: String(this.$route.query.commodityCode),
    };
  }

  get payload(): any {
    return {
      ...this.payloadSectionOne,
      ...this.payloadSectionTwo,
    };
  }

  get payloadSectionOne() {
    return {
      sttManual: this.manualBooking.sttManual,
      referenceExternal: !this.manualBooking.referenceExternal
        ? ""
        : this.manualBooking.referenceExternal,
      productName: this.manualBooking.productName,
      originCity:
        this.origin.cityCode || this.manualBooking.originCity.cityCode,
      originDistrict: this.origin.code || this.manualBooking.originCity.code,
      destinationCity: this.manualBooking.destinationCity?.cityCode || "",
      destinationDistrict: this.manualBooking.destinationCity?.code || "",
      phoneSender: `${this.manualBooking.phoneCodeSender.substring(1)}${
        this.manualBooking.phoneSender
      }`,
      phoneCodeSender: this.manualBooking.phoneCodeSender,
      phoneFlagSender: this.manualBooking.phoneFlagSender,
      phoneReceiver: `${this.manualBooking.phoneCodeReceiver.substring(1)}${
        this.manualBooking.phoneReceiver
      }`,
      phoneCodeReceiver: this.manualBooking.phoneCodeReceiver,
      phoneFlagReceiver: this.manualBooking.phoneFlagReceiver,
      sender:
        typeof this.manualBooking.sender === "object"
          ? this.manualBooking.sender.customerName
          : this.manualBooking.sender,
      receiver:
        typeof this.manualBooking.receiver === "object"
          ? this.manualBooking.receiver.customerName
          : this.manualBooking.receiver,
      addressSender: this.manualBooking.addressSender,
      addressReceiver: this.manualBooking.addressReceiver,
    };
  }

  get payloadSectionTwo() {
    return {
      sttGrossWeight: Number(
        this.totalGrossWeight.toString().replace(",", ".")
      ),
      sttVolumeWeight: Number(
        this.totalVolumeWeight.toString().replace(",", ".")
      ),
      sttChargeableWeight: this.isCampaign
        ? Number(this.totalCampaignChargeableWeight)
        : Number(this.totalChargeableWeight.toString().replace(",", ".")),
      addressReceiverType: this.manualBooking.addressReceiverType,
      commodityCode: this.manualBooking.commodityCode.code,
      insurance: this.manualBooking.insurance,
      statusGoods: this.manualBooking.statusGoods,
      estimationPrice: Number(
        this.manualBooking.estimationPrice
          ?.replace(/[.]/g, "")
          ?.replaceAll(",", ".")
      ),
      taxNumber: this.manualBooking.taxNumber,
      isWoodpack: this.manualBooking.isWoodpack,
      isCOD: this.manualBooking.isCOD || this.manualBooking.isDFOD,
      isDFOD: this.manualBooking.isDFOD,
      isCODClient:
        (this.manualBooking.isCOD || this.manualBooking.isDFOD) &&
        this.formType !== "manual",
      isDFODClient: this.manualBooking.isDFOD && this.formType !== "manual",
      isPAD: this.manualBooking.isPAD,
      codAmount: this.manualBooking.isCOD
        ? this.formType === "manual" && this.dataProfile.account_type !== 'client'
          ? Number(
            this.manualBooking.estimationPrice
            ?.replace(/[.]/g, "")
            ?.replaceAll(",", ".")
            )
          : Number(this.manualBooking.codAmount.toString().replace(/[.]/g, ""))
        : 0,
      isSaveSender: this.manualBooking.isSaveSender,
      isSaveReceiver: this.manualBooking.isSaveReceiver,
      sttPieces: this.convertedArr,
      isDO: this.isDO,
      isMixpack: this.isMixpack,
      sttPiecePerPack: Number(this.manualBooking.piecePerPack),
      sttNextCommodity: this.manualBooking.nextCommodity,
      clientName: this.manualBooking.clientName,
      postalCodeDestination: this.manualBooking.postalCodeDestination?.id,
      sttAttachFiles: this.additionalInfo.files.filter((item: string) => item),
      sttCommodityDetail: this.additionalInfo.commodity,
      sttRecipientEmail: this.additionalInfo.email,
      sttKtpImage: this.additionalInfo.files[2],
      sttTaxImage: this.additionalInfo.files[3],
      sttWeightAttachFiles: [], //this.fileAdjustmentsFilter client only
    };
  }

  get convertedArr() {
    return this.listKGs.map((key: any) => ({
      stt_piece_length: key.dimension.length,
      stt_piece_width: key.dimension.width,
      stt_piece_height: key.dimension.height,
      stt_piece_gross_weight: Number(
        key.grossWeight.toString().replace(",", ".")
      ),
      stt_piece_volume_weight: key.volumeWeight,
    }));
  }

  get convertedArrPiecesPosterProduct() {
    return this.listKGs.map((key: any) => ({
      pieceLength: key.dimension.length,
      pieceWidth: key.dimension.width,
      pieceHeight: key.dimension.height,
      pieceGrossWeight: Number(key.grossWeight.toString().replace(",", ".")),
    }));
  }

  get isMixpack() {
    return AccountController.accountData.account_type === "client"
      ? AccountController.accountData.account_type_detail.isMixpack
      : false;
  }

  get isDO() {
    return AccountController.accountData.account_type === "client"
      ? AccountController.accountData.account_type_detail.isDO
      : false;
  }

  setCodDfodClient() {
    this.refs = this.$refs;
    if (
      this.formType === "manual" &&
      this.getAccount.accountType === "client"
    ) {
      this.refs.packageDetail.setStatus(
        this.getAccount.accountTypeDetail.isCod,
        this.getAccount.accountTypeDetail.isDfod,
        "non-cod"
      );
    }
  }

  getCustomerList() {
    BookingController.getCustomerList({ search: "", cache: true });
  }

  getCommodityList() {
    CommodityController.getCommodityList(new RequestListCommodity());
  }

  // route navigation
  goBack() {
    this.$router.push("/shipment/booking");
  }

  get title() {
    return this.$t(`${this.$route.meta.name}`);
  }

  get manualBooking(): any {
    return BookingController.manualBooking;
  }
  errorEstimationPrice = false;

  getWoodPacking() {
    this.manualBooking.isWoodpack = false;
    const renderWoodPacking: any = this.$refs.woodpacking;
    renderWoodPacking.resetData();
    renderWoodPacking.getConfig();
  }

  get isFormBookManual() {
    return this.formType === "manual";
  }

  get origin() {
    const detail = LocationController.districtDetail;
    return {
      id: detail.id,
      name: `${detail.name}, ${detail.cityName}`,
      code: `${detail.code}`,
      cityId: detail.cityId,
      cityCode: `${detail.cityCode}`,
    };
  }

  get cityForWoodPacking() {
    return this.origin.cityCode || this.manualBooking.originCity.cityCode;
  }

  get eligibleToWoodpacking() {
    return (
      (this.formType === "manual" &&
        this.getAccount.accountType === "client") ||
      (this.formType === "client" &&
        this.getAccount.accountType === "partner") ||
      (this.formType === "client" && this.getAccount.accountType === "internal")
    );
  }

  get formType() {
    return this.$route.meta.formType;
  }

  get getAccount(): any {
    return {
      accountType: AccountController.accountData.account_type,
      cityCode: AccountController.accountData.account_type_detail
        .partnerLocation
        ? AccountController.accountData.account_type_detail.partnerLocation
            .city_code
        : "",
      accountClientId: AccountController.accountData.account_type_detail.id
        ? AccountController.accountData.account_type_detail.id
        : "",
      isCodBooking:
        AccountController.accountData.account_type_detail.partnerIsCodBooking,
      accountTypeDetail: AccountController.accountData.account_type_detail,
    };
  }
  // error embargo
  get isErrorEmbargo() {
    return BookingController.isErrorEmbargo;
  }

  errorEmbargo = {
    originCity: false,
    destinationCity: false,
    productType: false,
    comodity: false,
    weightStatus: false,
    lengthStatus: false,
  };

  updateStatusErrorEmbargo(status: boolean) {
    this.errorEmbargo.originCity = status;
    this.errorEmbargo.destinationCity = status;
    this.errorEmbargo.productType = status;
    this.errorEmbargo.comodity = status;
    this.errorEmbargo.weightStatus = status;
    this.errorEmbargo.lengthStatus = status;
  }

  onUpdateEmbargoStatusFail() {
    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(false);
    }
  }

  get configHeavyWeightMinimum() {
    return HeavyWeightSurchargeController.heavyWeightMinimum;
  }

  async getConfigHws(product: any, commodity: any) {
    await HeavyWeightSurchargeController.getDetailMinimumHeavyWeightSurcharge({
      id: 1,
      product,
      commodity,
    });
    if (
      this.configHeavyWeightMinimum &&
      !this.configHeavyWeightMinimum.minimumExist
    ) {
      await HeavyWeightSurchargeController.getDetailMinimumHeavyWeightSurcharge(
        {
          id: 2,
          product,
          commodity,
        }
      );
    }
  }

  async onChangeCommodities() {
    this.onUpdateEmbargoStatusFail();
    if (
      this.manualBooking.productName &&
      this.manualBooking.commodityCode.code
    ) {
      await this.getConfigHws(
        this.manualBooking.productName,
        this.manualBooking.commodityCode.code
      );
      this.onSetErrorHws();
    }
  }

  onChangeDeliveryFeeType = debounce((self: any) => {
    self.pickProductUpselling(self.manualBooking.destinationCity);
  }, 500);

  get callUpselling() {
    return (
      this.manualBooking.destinationCity.code &&
      this.manualBooking.commodityCode.code &&
      this.totalChargeableWeight
    );
  }

  updateTotalChargeableWeight(value: any) {
    this.totalChargeableWeight = value;
  }
  updateTotalCampaignChargeableWeight(value: any) {
    this.totalCampaignChargeableWeight = value;
  }

  updateTotalGrossWeight(value: any) {
    this.totalGrossWeight = value;
  }

  updateTotalVolumeWeight(value: any) {
    this.totalVolumeWeight = value;
  }

  totalChargeableWeight: any = "0";
  totalGrossWeight: any = "0";
  totalVolumeWeight: any = "0";
  totalCampaignChargeableWeight: any = "0";

  closeModalError() {
    BookingController.setError(false);
    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(true);
    }
  }

  get isErrorHws() {
    return this.listKGs.map((key: any) => key.errorHws).includes(true);
  }

  onSetErrorHws() {
    //set error hws
    this.showWarningHws =
      this.configHeavyWeightMinimum.minimumExist &&
      this.listKGs.length < 2 &&
      this.listKGs[0]?.grossWeight?.replace(",", ".") >=
        this.configHeavyWeightMinimum.heavyWeightMinimum;
    this.listKGs.forEach((key: any, index: number) => {
      this.listKGs[index].errorHws =
        this.configHeavyWeightMinimum.minimumExist &&
        +key.grossWeight?.replace(",", ".") >=
          this.configHeavyWeightMinimum.heavyWeightMinimum &&
        this.listKGs.length > 1;
    });
  }

  // error indicator mandatory
  currentFieldIndex = 0;
  setFieldIndex(index: number) {
    if (index > this.currentFieldIndex) {
      this.currentFieldIndex = index;
    }
  }

  get errorMandatory() {
    return [
      ...this.errorMandatoryDetailDelivery,
      ...this.errorMandatoryDetailGood
    ];
  }

  get errorMandatoryDetailDelivery() {
    return [
      this.errorMandatoryClientColumn,
      this.errorProductcolumn,
      this.errorMandatoryExternalReffColumn,
      !this.payload.sender && this.currentFieldIndex > 3,
      !this.payload.receiver && this.currentFieldIndex > 4,
      !this.manualBooking.phoneSender && this.currentFieldIndex > 5,
      !this.manualBooking.phoneReceiver && this.currentFieldIndex > 6,
      !this.payload.addressSender && this.currentFieldIndex > 7,
      !this.payload.addressReceiver && this.currentFieldIndex > 8,
      this.errorMandatoryDestinationColumn,
      !this.payload.destinationDistrict && this.currentFieldIndex > 10
    ];
  }

  get errorProductcolumn() {
    return !this.payload.productName && this.currentFieldIndex > 1;
  }

  get errorMandatoryClientColumn() {
    return (
      !this.payload.clientName &&
      (this.getAccount.accountType === "client") &&
      this.currentFieldIndex > 0
    );
  }

  get errorMandatoryExternalReffColumn() {
    return (
      !this.payload.referenceExternal &&
      ((this.isClientDO) ||
        (this.getAccount.accountType === "client" && this.isDO)) &&
      this.currentFieldIndex > 2
    );
  }

  get errorMandatoryDestinationColumn() {
    return (
      !this.manualBooking.originCity?.code &&
      this.getAccount.accountType === "internal" &&
      this.currentFieldIndex > 9
    );
  }

  get errorMandatoryDetailGood() {
    const newFiles = this.additionalInfo.files.filter((item: string) => item);
    return [
      !this.payload.commodityCode && this.currentFieldIndex > 11,
      false,
      !this.payload.insurance && this.currentFieldIndex > 13,
      !this.payload.estimationPrice && this.currentFieldIndex > 14,
      !this.manualBooking.codAmount && this.currentFieldIndex > 15,
      false,
      this.errorManadatoryShipmentStatus,
      this.errorMandatoryPiecePerPack,
      this.errorMandatoryNextCommodity,
      this.errorMandatoryAdditionalInfoCommodity,
      this.errorMandatoryAdditionalInfoEmail,
      newFiles.length < 3 &&
        this.isProductInterpack &&
        this.currentFieldIndex > 21
    ];
  }

  get errorManadatoryShipmentStatus() {
    return (
      (!this.payload.statusGoods) &&
      (this.isFtzAccount) &&
      this.currentFieldIndex > 17
    );
  }

  get errorMandatoryPiecePerPack() {
    return (
      !this.payload.sttPiecePerPack &&
      this.isFtzAccount &&
      this.currentFieldIndex > 18
    );
  }

  get errorMandatoryNextCommodity() {
    return (
      !this.payload.sttNextCommodity &&
      this.isFtzAccount &&
      this.currentFieldIndex > 19
    );
  }

  get errorMandatoryAdditionalInfoCommodity() {
    return (
      !this.additionalInfo.commodity &&
      this.currentFieldIndex > 20
    );
  }

  get errorMandatoryAdditionalInfoEmail() {
    return (
      !this.additionalInfo.email &&
        this.currentFieldIndex > 20.5
    );
  }

  get isErrorSpesificRate() {
    return BookingController.errorSpesificByRate;
  }

  get isErrorAdd() {
    return BookingController.isError;
  }

  get errorCause() {
    return BookingController.isErrorCause;
  }

  additionalInfo: any = {
    commodity: "",
    email: "",
    files: [],
  };

  errAditionalInfo = {
    email: false,
    commodity: false,
    files: false,
  };

  get isProductInterpack() {
    return this.manualBooking.productName === "INTERPACK";
  }


  get eligibleAdditionalInfo() {
    return (
      FlagsMedusa.flag_release_interpack_additional_info.isEnabled() &&
      ((this.detailDistrict.countryCode &&
        this.detailDistrict.countryCode !== "ID") ||
        this.manualBooking.productName === "INTERPACK")
    );
  }

  get isFtzAccount() {
    return this.getAccount.accountType === "internal"
      ? this.manualBooking.originCity?.isFtz === "yes"
      : AccountController.accountData.account_location?.city_free_trade_zone ===
          "yes";
  }

  estimateSla = {
    slaMin: 0,
    slaMax: 0,
    transport: "",
  };

  get isShowWarningSla() {
    return this.estimateSla.slaMin >= 7;
  }

  fetchSlaEstimation() {
    MainAppController.showLoading();
    BookingController.checkTransportAndEstimateSlaBooking({
      originCity:
        this.origin.cityCode || this.manualBooking.originCity.cityCode,
      destinationCity: this.manualBooking.destinationCity?.cityCode || "",
      originDistrict: this.origin.code || this.manualBooking.originCity.code,
      destinationDistrict: this.manualBooking.destinationCity.code,
      product: this.manualBooking.productName,
    }).then((response) => {
      this.estimateSla = response;
    });
    MainAppController.closeLoading();
  }

  get isEmbargo() {
    return BookingController.productHaveEmbargo;
  }

  get titleErrorspesification() {
    return BookingController.titleErrorspesification;
  }

  get openSuccess() {
    return BookingController.showModalSuccess;
  }

  get messageSuccess() {
    return BookingController.messageSuccess;
  }

  isSubmit = false;

  booking() {
    this.isSubmit = true;
    if (this.formType !== "client") {
      this.submitBookManualParam();
    } else {
      this.submitBookCoorporateParam();
    }
  }

  submitBookCoorporateParam() {
    BookingController.addBookingCorporate({
      payload: new AddBookingCorporate({
        sttClient: this.manualBooking.clientName.id,
        sttNo: this.payload.sttManual,
        sttShipmentId: "",
        sttNoRefExternal: this.payload.referenceExternal,
        sttTaxNumber: this.payload.taxNumber,
        sttGoodsEstimatePrice: this.payload.estimationPrice,
        sttGoodsStatus: this.payload.statusGoods,
        sttOriginCityId: this.payload.originCity,
        sttOriginDistrictId: this.payload.originDistrict,
        sttDestinationCityId: this.payload.destinationCity,
        sttDestinationDistrictId: this.payload.destinationDistrict,
        sttSenderName: this.payload.sender,
        sttSenderPhone: this.payload.phoneSender,
        sttSenderAddress: this.payload.addressSender,
        sttRecipientName: this.payload.receiver,
        sttRecipientAddress: this.payload.addressReceiver,
        sttRecipientAddressType: this.payload.addressReceiverType,
        sttRecipientPhone: this.payload.phoneReceiver,
        sttInsuranceType: this.payload.insurance,
        sttProductType: this.payload.productName,
        sttCommodityCode: this.payload.commodityCode,
        sttClientSttId: 0,
        sttVendorSttId: 0,
        sttIsCod: this.payload.isCODClient,
        sttIsDfod: this.payload.isDFODClient,
        sttIsWoodpacking: this.payload.isWoodpack,
        sttPieces: this.payload.sttPieces,
        sttPiecePerPack: Number(this.manualBooking.piecePerPack),
        sttNextCommodity: this.manualBooking.nextCommodity,
        sttCodAmount: this.payload.codAmount,
        postalCodeDestination: this.manualBooking.postalCodeDestination?.id,
        sttWeightAttachFiles: [],
        sttRecipientEmail: this.additionalInfo.email,
        sttKtpImage: this.additionalInfo.files[2],
        sttTaxImage: this.additionalInfo.files[3],
        sttAttachFiles: this.payload.sttAttachFiles
      }),
      isBookingClient: true
    });
  }

  onEnterBooking(event: any) {
    this.isSubmit = false;
    if (event.key === "Enter") {
      if (this.isDisabledButton) {
        this.setFieldIndex(19);
        return;
      }
      this.enterbookingSuccess();
    }
  }

  enterbookingSuccess() {
    if (!this.isSubmit && !this.isLoading) {
      if (!this.openSuccess) {
        this.booking();
        return;
      }
    }
  }

  async submitBookManualParam() {
    try {
      const createbooking: ResponsePayload = await BookingController.addManualBooking(
        this.payloadBookingManual
      );

      await BookingController.onSuccessBooking({
        sttNumber: createbooking.sttNumber,
        sttId: createbooking.sttId,
      });
    } catch (error) {
      BookingController.generateErrorBooking({
        err: error,
        payload: this.payloadBookingManual,
        errorFor: "book",
        calllFunctionAgain: this.submitBookManualParam,
      });
    } finally {
      BookingController.finalCreateBooking();
    }
  }

  get payloadBookingManual() {
    return new AddManualBooking({
      sttManual: this.payload.sttManual,
      referenceExternal: this.payload.referenceExternal,
      productName: this.payload.productName,
      originCity: this.payload.originCity,
      originDistrict: this.payload.originDistrict,
      destinationCity: this.payload.destinationCity,
      destinationDistrict: this.payload.destinationDistrict,
      phoneSender: this.payload.phoneSender,
      phoneReceiver: this.payload.phoneReceiver,
      sender: this.payload.sender,
      receiver: this.payload.receiver,
      addressSender: this.payload.addressSender,
      addressReceiver: this.payload.addressReceiver,
      addressReceiverType: this.payload.addressReceiverType,
      sttGrossWeight: this.payload.sttGrossWeight,
      sttVolumeWeight: this.payload.sttVolumeWeight,
      sttChargeableWeight: this.payload.sttChargeableWeight,
      commodityCode: this.payload.commodityCode,
      insurance: this.payload.insurance,
      statusGoods: this.payload.statusGoods,
      estimationPrice: this.payload.estimationPrice,
      taxNumber: this.payload.taxNumber,
      isWoodpack: this.payload.isWoodpack,
      isCOD: this.payload.isCOD,
      isDFOD: this.payload.isDFOD,
      sttAttachFiles: this.payload.sttAttachFiles,
      sttCommodityDetail: this.payload.sttCommodityDetail,
      isSaveSender: this.payload.isSaveSender,
      isSaveReceiver: this.payload.isSaveReceiver,
      sttPieces: this.payload.sttPieces,
      isDO: this.payload.isDO,
      isMixpack: this.payload.isMixpack,
      sttPiecePerPack: this.payload.sttPiecePerPack,
      sttNextCommodity: this.payload.sttNextCommodity,
      sttCodAmount: this.payload.codAmount,
      postalCodeDestination: this.payload.postalCodeDestination,
      isPAD: this.payload.isPAD,
      sttWeightAttachFiles: [],
      sttRecipientEmail: this.additionalInfo.email,
      sttKtpImage: this.additionalInfo.files[2],
      sttTaxImage: this.additionalInfo.files[3],
    });
  }

  listKGs: any = [];

  refs: any = "";

  isProductSelected = false;
  async onSelectProduct(obj: any) {
    this.refs = this.$refs;
    this.refs.sttManualReference.validateSttManual(
      this.manualBooking.sttManual
    );
    if (obj) {
      if (obj.status === "ACTIVE") {
        if (
          this.manualBooking.productName !== "INTERPACK" &&
          obj.name === "INTERPACK"
        ) {
          this.listKGs = [this.listKGs[0]];
        }
        this.isProductSelected = true;
        BookingController.setProductName(obj.name);
        for (let i = 0; i < this.listKGs.length; i++) {
          this.refs.goodsWeight?.calculationVolumeWeight(i);
        }
        this.refs.sttManualReference?.validateSttManual(
          this.manualBooking.sttManual
        );
        this.isClientDO = this.statusCODClient;
        this.fetchSlaEstimation();
      } else {
        this.error.product = true;
      }
      this.setStatusInsurance(obj.name, obj.status === "ACTIVE", this.refs);
      // this.callZiCode();
    } else {
      this.isProductSelected = false;
      this.isClientDO = true;
      this.manualBooking.sttManual = "";
    }

    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(false);
    }
    if (
      this.manualBooking.productName &&
      this.manualBooking.commodityCode.code
    ) {
      // get config hws
      // on set error hws
      await this.getConfigHws(obj.name, this.manualBooking.commodityCode.code);
      this.onSetErrorHws();
    }
  }

  resetZipCode() {
    if (this.accountIsNonForeign) {
      BookingController.setPostalCodeDestination("");
    }
  }

  disableAsuranceOtoPack = false;
  statusProduct = false;
  setStatusInsurance(
    productName: string,
    productStatus: boolean,
    ref: any = ""
  ) {
    this.statusProduct = productStatus;
    const product =
      (productName === "OTOPACK150" || productName === "OTOPACK250") &&
      productStatus;
    if (product) {
      // validate insurance
      ref.packageDetail.automaticSetCheckbox(
        +this.manualBooking.estimationPrice?.replace(/\./g, "")
      );
      this.disableAsuranceOtoPack = true;
      this.processErrorEstimationPrice(this.manualBooking.estimationPrice);
    } else {
      this.disableAsuranceOtoPack = false;
      ref.packageDetail.automaticSetCheckbox(
        +this.manualBooking.estimationPrice?.replace(/\./g, "")
      );
    }
  }

  processErrorEstimationPrice(price: string) {
    const isNonCodValidate = !this.manualBooking.isCOD;
    const estimation = Number(price.replace(/\./g, "").replaceAll(",", "."));
    if (isNonCodValidate) {
      this.validateOttopack(estimation);
    }
  }

  validateOttopack(estimation: number) {
    if (this.disableAsuranceOtoPack && this.estimationForOttopack(estimation)) {
      // this.errorEstimationPrice = true;
    } else {
      this.errorEstimationPrice = false;
    }
  }

  estimationForOttopack(estimasi: number) {
    return estimasi > 0 && estimasi < 5000000;
  }

  isInvalidCodAndDfod(isCodValidate: boolean, isDfodValidate: boolean) {
    return (
      (!this.manualBooking.isCOD && isCodValidate) ||
      (!this.manualBooking.isDFOD && isDfodValidate)
    );
  }
  errorDestintaionProductUpselling = false;
  setErrorDestintaionProductUpselling(status: boolean) {
    this.errorDestintaionProductUpselling = status;
    BookingController.setErrorDestination(status);
  }
  get isOnePack() {
    return (
      (this.formType === "manual" &&
        this.getAccount.accountType === "client") ||
      this.formType === "client"
    );
  }
  get isShowJumbopack() {
    return (
      ((this.formType === "manual" &&
        this.getAccount.accountType === "client") ||
        this.formType === "client") &&
      flags.show_jumbopack_on_booking_page.isEnabled()
    );
  }
  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }

  error = {
    sttManual: false,
    productName: false,
    taxNumber: false,
    successPhoneSender: false,
    phoneSender: false,
    phoneCodeSender: false,
    phoneCharMinimalSender: false,
    errorMessagePhoneSender: "",
    successPhoneReceiver: false,
    phoneReceiver: false,
    phoneCodeReceiver: false,
    phoneCharMinimalReceiver: false,
    errorMessagePhoneReceiver: "",
    estimationPrice: false,
    grossWeight: false,
    product: false,
    insurance: false,
    errorMandatoryClientColumn: false,
  };

  get isVendorNinjaDestination() {
    return BookingController.isVendorNinjaDestination;
  }

  setIsVendorNinjaDestination(val: boolean) {
    BookingController.setIsVendorNinjaDestination(val);
  }

  detailDistrict = new DistrictData();

  async onSelectDestination(type = "") {
    if (!type) {
      this.postalCodeDestinationOptions = [];
    }
    this.refs = this.$refs;
    BookingController.setProductName("");
    BookingController.setErrorDestination(false);
    if (!this.refs?.recipientDetail) return;
    if (!this.manualBooking.destinationCity?.id) return;
    const resp = await this.refs.recipientDetail.fetchDistrictId(
      this.manualBooking.destinationCity?.id
    );
    if (resp.name !== "") {
      this.detailDistrict = resp;
    }
    // reset photo here

    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(false);
    }
    if (this.isVendorNinjaDestination) {
      this.setIsVendorNinjaDestination(false);
    }

    this.checkIfCod(
      this.manualBooking.destinationCity?.isCod === "yes",
      this.isClientCOD
    );


    this.callZiCode(type);
    this.callZipCodeForForeign(type);
    this.pickProductUpselling(this.manualBooking.destinationCity);
    this.getDocumentInternational();
  }

  pickProductUpselling(val: any) {
    if (val) {
      this.loadUpsellingProductByCondition();
    } else {
      this.resetProductUpselling();
    }
  }

  resetProductUpselling() {
    const renderPoster: any = this.$refs.renderPoster;
    renderPoster?.resetAllProduct();
  }

  loadUpsellingProductByCondition() {
    this.loadUpsellingProductForManualBook();
    this.loadUpsellingProductForClientBook();
  }

  loadUpsellingProduct() {
    const visiblePricePosterProduct =
      this.isFormBookManual && this.getAccount.accountType === "partner";
    const renderPoster: any = this.$refs.renderPoster;
    renderPoster?.getPosterProduct(
      "",
      this.origin.code || this.manualBooking.originCity.code,
      this.manualBooking.destinationCity.code,
      visiblePricePosterProduct,
      this.clientlientIdForProductUpselling.accountRefId,
      this.clientlientIdForProductUpselling.accountType,
      this.manualBooking.estimationPrice,
      this.manualBooking.commodityCode,
      this.convertedArrPiecesPosterProduct,
      this.payload.isCOD,
      this.payload.isDFOD
    );
  }

  get clientlientIdForProductUpselling() {
    let accountRefId = 0;
    let accountType = "";

    if (this.isFormBookManual && this.getAccount.accountType === "partner") {
      accountRefId = 0;
      accountType = "pos";
    } else if (
      this.formType === "client" &&
      this.getAccount.accountType === "partner"
    ) {
      accountRefId = this.manualBooking.clientName.id;
      accountType = "client";
    } else if (
      this.isFormBookManual &&
      this.getAccount.accountType === "client"
    ) {
      accountRefId = this.getAccount.accountClientId;
      accountType = "client";
    } else {
      accountRefId = this.manualBooking.clientName.id;
      accountType = "client";
    }
    return {
      accountRefId,
      accountType,
    };
  }

  loadUpsellingProductForManualBook() {
    if (
      this.isFormBookManual &&
      this.OriginAndDestinationFullfilledForManualBook
    ) {
      this.loadUpsellingProduct();
    }
  }

  loadUpsellingProductForClientBook() {
    if (this.fullFilledUpsellingProductForClient) {
      this.loadUpsellingProduct();
    }
  }

  get originFullfilledForManualBook() {
    return this.manualBooking.originCity || this.origin.id;
  }

  get fullFilledUpsellingProductForClient() {
    return (
      this.originFullfilledForManualBook &&
      this.clientNameAndDestinationFullfilledForManualBook
    );
  }

  get OriginAndDestinationFullfilledForManualBook() {
    return this.origin.id && this.manualBooking.destinationCity;
  }

  get clientNameAndDestinationFullfilledForManualBook() {
    return this.manualBooking.clientName && this.manualBooking.destinationCity;
  }
  async callZipCodeForForeign(type: string) {
    if (this.manualBooking.destinationCity && !this.accountIsNonForeign) {
      this.callMainZipCode(type);
    }
  }

  get zipCodeForDestinationAndProduct() {
    return (
      this.manualBooking.destinationCity &&
      this.manualBooking.productName === "INTERPACK"
    );
  }

  // postal code
  postalCodeDestinationOptions: any = [];
  setPostalCode(value: any) {
    this.postalCodeDestinationOptions = value;
  }
  setPostalCodeDestination(value: string) {
    if (value) {
      BookingController.setPostalCodeDestination({
        id: value,
        name: value,
      });
      return;
    }
    BookingController.setPostalCodeDestination("");
  }

  async callMainZipCode(type: string) {
    const responseDistrictData = await LocationController.getDetailDistrict({
      id: this.manualBooking.destinationCity.id,
      notSaveDetail: true,
    });
    this.postalCodeDestinationOptions = responseDistrictData.destinationListZipCode.map(
      (item: any) => {
        return {
          id: item,
          name: item,
          districtName: responseDistrictData.name,
          cityName: responseDistrictData.cityName,
          code: responseDistrictData.code,
          vendorCode: responseDistrictData.vendorCode,
          cityCode: responseDistrictData.cityCode,
          type: responseDistrictData.type,
          isCod: responseDistrictData.isCod,
          status: responseDistrictData.status
        };
      }
    );
    if (!responseDistrictData.destinationListZipCode.length) {
      this.postalCodeDestinationOptions = [];
      if (type !== "zipcode") {
        BookingController.setPostalCodeDestination("");
      }
      return;
    }

    if (responseDistrictData.destinationZipCode.includes(",")) {
      this.postalCodeDestinationOptions = responseDistrictData.destinationListZipCode.map(
        (item: any) => {
          return {
            id: item,
            name: item,
            districtName: responseDistrictData.name,
            cityName: responseDistrictData.cityName,
            code: responseDistrictData.code,
            vendorCode: responseDistrictData.vendorCode,
            cityCode: responseDistrictData.cityCode,
            type: responseDistrictData.type,
            isCod: responseDistrictData.isCod,
            status: responseDistrictData.status
          };
        }
      );

      BookingController.setPostalCodeDestination(
        this.postalCodeDestinationOptions[0]
      );
    } else {
      BookingController.setPostalCodeDestination({
        id: responseDistrictData.destinationZipCode,
        name: responseDistrictData.destinationZipCode,
        districtName: responseDistrictData.name,
        cityName: responseDistrictData.cityName,
        code: responseDistrictData.code,
        vendorCode: responseDistrictData.vendorCode,
        cityCode: responseDistrictData.cityCode,
        type: responseDistrictData.type,
        isCod: responseDistrictData.isCod,
        status: responseDistrictData.status
      });
      this.postalCodeDestinationOptions = responseDistrictData.destinationListZipCode.map((zc: string) => {
          return {
            id: zc,
            name: zc,
            districtName: responseDistrictData.name,
            cityName: responseDistrictData.cityName,
            code: responseDistrictData.code,
            vendorCode: responseDistrictData.vendorCode,
            cityCode: responseDistrictData.cityCode,
            type: responseDistrictData.type,
            isCod: responseDistrictData.isCod,
            status: responseDistrictData.status
          };
        }
      );
    }
  }

  async callZiCode(type: string) {
    this.callMainZipCode(type);
  }

  getClientID() {
    if (this.getAccount.accountType === "client") {
      ClientManagementController.getDetail(this.getAccount.accountClientId);
    }
  }

  get detailClientID() {
    return ClientManagementController.detailClientManagement;
  }
  checkIfCod(
    destination: boolean,
    client: boolean = this.detailClientID.clientIsCod
  ) {
    if (this.formType === "client") {
      if (!client || !destination) {
        BookingController.setCOD(false);
        BookingController.setDfod(false);
        BookingController.setCODAmount(0);
      }
    } else {
      if (!destination) {
        BookingController.setCOD(false);
        BookingController.setDfod(false);
        BookingController.setCODAmount(0);
      }
    }
  }

  get disableSaveReceiver() {
    return (
      !this.manualBooking.receiver ||
      !this.manualBooking.phoneReceiver ||
      !this.manualBooking.phoneCodeReceiver ||
      !this.manualBooking.addressReceiver ||
      this.error.phoneReceiver ||
      this.error.phoneCodeReceiver
    );
  }

  showWarningHws = false;

  // Validation
  get isMaxAddKgs() {
    return this.listKGs.length < 15;
  }

  productConfigurationRuleList: ProductConfigurationRuleList = new ProductConfigurationRuleList();

  get productConfigRuleActive() {
    const isRetailAllowed =
      this.isFormBookManual && this.getAccount.accountType === "partner";

    return this.productConfigurationRuleList.findProductConfigRule(
      this.manualBooking.productName,
      this.manualBooking.clientName?.id || this.detailClientID.id,
      isRetailAllowed
    );
  }

  get isCampaign() {
    return this.productConfigRuleActive.isCampaign;
  }

  get isDisabledButton() {
    return this.isFormBookManual
      ? this.disableButtonBookManual
      : this.disableButtonClient;
  }

  get disableButtonBookManual() {
    return (
      !this.isFormValid ||
      this.validateTotalGrossWeight ||
      this.validateTotalVolumeWeight ||
      this.isShipperAndConsigneeAddressIdentical ||
      !this.isValidPostalCodeDestination ||
      this.validateGrossWeightJumbopack
    );
  }
  get isValidPostalCodeDestination() {
    if (this.manualBooking.productName === "INTERPACK") {
      return !!this.manualBooking.postalCodeDestination?.id;
    }
    return true;
  }

  get validateGrossWeightJumbopack() {
    const value = this.totalChargeableWeight < 30;
    return this.manualBooking.productName === "JUMBOPACK" && !!value;
  }

  get isErrorCommodity() {
    return this.manualBooking.commodityCode?.status === "inactive";
  }

  get isFormValid() {
    return (
      this.formValidationShipmentDetail &&
      this.formValidationShipmentGood &&
      !this.errorState &&
      this.fomValidationOthers &&
      this.isFormValidationError &&
      this.manualBooking.postalCodeDestination?.id
    );
  }

  get isShipperAndConsigneeAddressIdentical() {
    if (
      this.manualBooking.addressSender === "" ||
      this.manualBooking.addressReceiver === ""
    ) {
      return false;
    }
    return (
      this.manualBooking.addressSender.toLowerCase() ===
      this.manualBooking.addressReceiver.toLowerCase()
    );
  }

  get disableButtonClient() {
    return !this.isFormValidClient ||
    this.validateTotalGrossWeight ||
    this.validateTotalVolumeWeight ||
    this.isShipperAndConsigneeAddressIdentical ||
    !this.isValidPostalCodeDestination ||
    this.validateGrossWeightJumbopack
  }

  get isFormValidClient() {
    return (
      this.formValidationClientShipmentDetail &&
      !this.errorState &&
      this.formValidationClientShipmentGood &&
      this.formValidationClientShipmentOthers &&
      this.formValidationClientShipmentError &&
      this.manualBooking.postalCodeDestination?.id
    );
  }

  get formValidationClientShipmentDetail() {
    return (
      this.payload.destinationCity &&
      this.payload.destinationDistrict &&
      this.payload.phoneSender &&
      this.payload.phoneReceiver &&
      this.payload.sender &&
      this.payload.receiver &&
      this.payload.addressSender &&
      this.payload.addressReceiver
    );
  }

  get formValidationClientShipmentGood() {
    return (
      this.payload.productName &&
      this.payload.sttGrossWeight &&
      this.payload.sttChargeableWeight &&
      this.payload.commodityCode &&
      this.payload.insurance &&
      this.payload.estimationPrice &&
      this.checkIntersection()
    );
  }

  get formValidationClientShipmentOthers() {
    return (
      this.manualBooking.clientName &&
      !this.isErrorClient &&
      this.checkIsOrigincityFilled &&
      this.payload.addressReceiverType &&
      this.isFtzRequiredForm &&
      this.validationVendorNinja &&
      !this.errorEstimationPrice
    );
  }

  get checkIsOrigincityFilled() {
    if (this.getAccount.accountType === "partner" && !this.payload.originCity) {
      return false;
    } else
      return !(
        this.getAccount.accountType === "internal" &&
        !this.manualBooking.originCity
      );
  }


  get formValidationClientShipmentError() {
    return (
      !this.error.product &&
      !this.error.insurance &&
      !this.error.sttManual
    );
  }

  get errorState() {
    let result: any = false;
    Object.entries({
      ...this.error,
      errorEstimationPrice: this.errorEstimationPrice,
      ...this.errAditionalInfo,
    }).forEach(([, value]) => {
      if (value) {
        result = value;
      }
    });
    return result;
  }

  get formValidationShipmentDetail() {
    return (
      this.payload.sender &&
      this.payload.receiver &&
      this.payload.phoneSender &&
      this.payload.phoneReceiver &&
      this.payload.addressSender &&
      this.payload.addressReceiver &&
      this.payload.addressReceiverType
    );
  }

  get formValidationShipmentGood() {
    return (
      this.payload.productName &&
      this.payload.commodityCode &&
      this.payload.insurance &&
      this.payload.estimationPrice &&
      this.payload.sttGrossWeight &&
      this.payload.sttChargeableWeight
    );
  }

  checkIntersection() {
    const result = true;
    for (const el of this.convertedArr) {
      if (
        this.piecesAreZero(
          el.stt_piece_gross_weight,
          el.stt_piece_width,
          el.stt_piece_length,
          el.stt_piece_height
        )
      ) {
        return false;
      }
    }
    return result;
  }

  piecesAreZero(weight: number, width: number, length: number, height: number) {
    return weight === 0 || width === 0 || length === 0 || height === 0;
  }

  get isFtzRequiredForm() {
    if (this.isFtzAccount && this.accountIsNonForeign) {
      return !!(
        this.manualBooking.taxNumber &&
        this.manualBooking.statusGoods &&
        this.manualBooking.piecePerPack &&
        this.manualBooking.nextCommodity
      );
    }

    if (this.isFtzAccount && !this.accountIsNonForeign) {
      return !!this.manualBooking.taxNumber;
    }

    return true;
  }

  get validationVendorNinja() {
    let result = true;
    if (this.isVendorNinjaDestination && this.listKGs.length > 1) {
      result = false;
    }
    return result;
  }

  get fomValidationOthers() {
    return (
      this.payload.destinationCity &&
      this.payload.destinationDistrict &&
      this.checkIntersection() &&
      this.isFtzRequiredForm &&
      this.validationVendorNinja
    );
  }


  get isFormValidationError() {
    return (
      !this.error.product &&
      !this.error.insurance &&
      !this.error.sttManual &&
      !this.errorEstimationPrice
    );
  }

  get validateTotalGrossWeight(): boolean {
    const total = Number(this.totalGrossWeight.replace(",", "."));
    return total > maxKg;
  }

  get validateTotalVolumeWeight(): boolean {
    let total = 0;
    this.listKGs.map((e: any) => (total += Number(e.volumeWeight)));
    return total > maxKg;
  }

  get disableDetailCommodity() {
    if (this.isProductInterpack) {
      return this.errAditionalInfo.commodity || (this.internationalDocumentDetail.commodity ? !this.additionalInfo.commodity : false);
    }

    return false;
  }

  get disableEmail() {
    if (this.isProductInterpack) {
      return this.errAditionalInfo.email || (this.internationalDocumentDetail.email ? !this.additionalInfo.email : false);
    }

    return false;
  }

  get disableProofCommodity() {
    if (this.isProductInterpack) {
      return this.errAditionalInfo.files;
    }

    return false;
  }

  get internationalDocumentDetail() {
    return InternationalDocumentController.internationalDocumentDetail;
  }

  get accountRefAndCommodity() {
    return {
      accountRefId: this.manualBooking.sttManual.id,
      commodityId: this.manualBooking.commodityCode.id,
    };
  }

  get isCodRetailApplied() {
    return this.manualBooking.isCOD || this.manualBooking.isDFOD;
  }

  get isGrossWeight() {
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    return x > y;
  }

  get isVolumeWeight() {
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    return x < y;
  }

  discount: Diskon = {
    isDiscount: false,
    totalTariff: 0,
    totalAfterDiscount: 0,
    totalBeforeDiscount: 0,
  };

  setDiscount(params: Diskon) {
    this.discount = {
      isDiscount: params.isDiscount,
      totalTariff: params.totalTariff,
      totalAfterDiscount: params.totalAfterDiscount,
      totalBeforeDiscount: params.totalBeforeDiscount,
    };
  }

  get isCODSelected() {
    return this.payload.isCOD === true && this.payload.isDFOD === false;
  }

  get isDFODSelected() {
    return this.payload.isDFOD;
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  getDocumentInternational() {
    const getdocinternational: any = this.$refs.getdocinternational;
    getdocinternational?.getInternationalDocumentDetail();
  }
}
