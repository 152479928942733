import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-red-lp-100 text-12px xxl:text-14px mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectGroup = _resolveComponent("SelectGroup")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_SelectGroup, {
      dataSearch: _ctx.allCountry,
      onSearch: _ctx.onFilterCountry,
      code: _ctx.code,
      flag: _ctx.flag,
      phone: _ctx.phone,
      onSetValue: _ctx.setValueCountry,
      onPasteText: _ctx.pasteText,
      error: 
        (_ctx.error.errorPhone && _ctx.phone) ||
          _ctx.error.errorMandatory ||
          _ctx.error.errorPhoneCode ||
          _ctx.error.phoneCharMinimal
      ,
      errorCaption: 
        _ctx.error.errorMandatory
          ? _ctx.errorMandatory
          : _ctx.error.errorPhone && _ctx.phone
          ? _ctx.$t(_ctx.error.message) || _ctx.$t('bookingShipment.error.invalidNumber')
          : _ctx.error.errorPhoneCode
          ? this.$t('bookingShipment.error.phoneCountryCode')
          : _ctx.error.phoneCharMinimal
          ? this.$t('bookingShipment.error.phoneCharacter')
          : ''
      ,
      maxLength: _ctx.maxLengthPhoneNumber(_ctx.phone),
      format: _ctx.formatNumber,
      onChange: _ctx.validatePhone,
      onFocus: _ctx.setFieldIndex,
      disabled: _ctx.disabled,
      prefixWidth: _ctx.prefixWidth,
      placeholder: "e.g 81119600999",
      class: "mt-2"
    }, null, 8, ["dataSearch", "onSearch", "code", "flag", "phone", "onSetValue", "onPasteText", "error", "errorCaption", "maxLength", "format", "onChange", "onFocus", "disabled", "prefixWidth"]),
    (_ctx.error.errorPhoneLabel)
      ? (_openBlock(), _createBlock("div", _hoisted_1, _toDisplayString(_ctx.error.errorPhoneLabel), 1))
      : _createCommentVNode("", true)
  ]))
}