
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue } from "vue-class-component";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import InputDimension from "@/app/ui/components/input-dimension/index.vue";
import Checkbox from "primevue/checkbox";
import DetailCalculation from "./payment-detail/calculation.vue";
import { CommodityController } from "@/app/ui/controllers/CommodityController";
import { BookingController } from "@/app/ui/controllers/BookingController";
import debounce from "lodash/debounce";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { PartnerController } from "@/app/ui/controllers/PartnerController";
import { ClientManagementController } from "@/app/ui/controllers/ClientManagementController";
import { maxLengthWeight } from "../component/modules";
import LpInputSearch from "@/app/ui/components/lp-input-search/index.vue";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";
import {
  maxKg,
  totalGrossWeightSikat,
  totalGrossWeightNonSikat,
  volumeWeightForProduct,
  uploadAdjustmentFile,
  deleteAdjustmentFile,
  detailClientManagement
} from "../booking-utils";
import SelectPhoneCountry from "../component/select-phone-country.vue";
import { codePhoneCountry } from "@/app/ui/views/shipment/booking/booking-utils";
import DialogPayment from "@/app/ui/components/dialog-payment/index.vue";
import formatInputMoney from "@/app/infrastructures/misc/common-library/FormatInputMoney";
import formattedNPWP from "@/app/infrastructures/misc/common-library/FormattedNPWP";
import handleInsuranceFromEstimationPrice from "@/app/infrastructures/misc/common-library/HandleInsuranceFromEstimationPrice";
import isDisableSttManual from "@/app/infrastructures/misc/common-library/IsDisableSttManual";
import isUrlUpdateVersion from "@/app/infrastructures/misc/common-library/IsUrlUpdateVersion";
import removeNewLineWhitespace from "@/app/infrastructures/misc/common-library/RemoveNewLineWhitespace";
import router from "@/app/ui/router";
import { ProductConfigurationRuleList } from "@/domain/entities/Product";
import { ProductController } from "@/app/ui/controllers/ProductController";
import { ProductConfigurationRuleRequestParams } from "@/data/payload/api/ProductApiRequest";
import WarningSlaTransportation from "@/app/ui/views/shipment/booking/component/error-message/warning-sla-transportation.vue";
import InformationCodRetail from "@/app/ui/views/shipment/booking/component/error-message/warning-cod-retail.vue";
import InfoInsurance from "@/app/ui/views/shipment/booking/component/mini-banner/info-insurance.vue";
import {
  ErrorPhoneNumber,
  ValidateInputPhoneNumber,
  ValidateMsisdn
} from "@/app/infrastructures/misc/common-library/ValidateInputPhoneNumber";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { CodConfigurationController } from "@/app/ui/controllers/CodConfigurationController";
import {
  formatPriceWithoutCurrency,
  parsingErrorResponse,
  formatInputMoneyWithDecimal
} from "@/app/infrastructures/misc/Utils";
import {
  ProductDeliveryData,
  InsuranceTypeDeliveryData
} from "@/domain/entities/Booking";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import ProductPoster from "../component/product-poster/index.vue";
import BannerProductType from "@/app/ui/views/shipment/booking/component/banner-product-type.vue";
import AdditionalInfo from "../component/additional-info/index.vue";
import RadioButton from "primevue/radiobutton";
import {
  AddManualBooking,
  AddBookingCorporate
} from "@/data/payload/api/BookingRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { flags } from "@/feature-flags";
import formatPriceRP from "@/app/infrastructures/misc/common-library/FormatPriceRP";
import formatPriceRM from "@/app/infrastructures/misc/common-library/FormatPriceRM";
import UploadPhotoAdjustment from "@/app/ui/views/shipment/booking/component/section-upload-photo-for-weight-adjusment/index.vue";
import { UploadMultiple } from "@/domain/entities/MainApp";
import Woodpacking from "../component/woodpacking/index.vue"
import DeliveryTypeClient from "../component/delivery-type-client/index.vue";
import { HeavyWeightSurchargeController } from "@/app/ui/controllers/HeavyWeightSurchargeController";
import SelectSearchV2 from "@/app/ui/components/select-search/index.vue";
import { PaginationV2 } from "@/domain/entities/Pagination";

interface Diskon {
  isDiscount: boolean;
  totalTariff: number;
  totalAfterDiscount: number;
  totalBeforeDiscount: number;
}

@Options({
  components: {
    WarningSlaTransportation,
    Title,
    Tooltip,
    InputDimension,
    Checkbox,
    DetailCalculation,
    LpInputSearch,
    SelectPhoneCountry,
    DialogPayment,
    InformationCodRetail,
    ProductPoster,
    BannerProductType,
    AdditionalInfo,
    RadioButton,
    InfoInsurance,
    UploadPhotoAdjustment,
    Woodpacking,
    DeliveryTypeClient,
    SelectSearchV2
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess && !this.forceRouter) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      BookingController.setShowModalSuccess(false);
      next();
    }
  },
  watch: {
    "payload.productName": function() {
      this.fetchSlaEstimation();
    },
    "payload.destinationCity": function() {
      this.fetchSlaEstimation();
    },
    "payload.originCity": function() {
      this.fetchSlaEstimation();
    },
    "payload.destinationDistrict": function() {
      this.fetchSlaEstimation();
    },
    "payload.originDistrict": function() {
      this.fetchSlaEstimation();
    },
    "payload.phoneSender": function() {
      let errorPhoneSender = new ErrorPhoneNumber();
      if (this.checkPhoneValidation) {
        errorPhoneSender = ValidateMsisdn(
          this.payload.phoneSender,
          "Masukkan nomor telepon dengan tipe MSISDN"
        );
      }
      errorPhoneSender = this.phoneMustMSISDN();
      this.error.successPhoneSender = errorPhoneSender.success;
      this.error.phoneSender = errorPhoneSender.error;
      this.error.errorMessagePhoneSender = errorPhoneSender.message;
    },
    "payload.phoneReceiver": function() {
      let errorPhoneReceiver = new ErrorPhoneNumber();
      if (this.checkPhoneValidation) {
        errorPhoneReceiver = ValidateMsisdn(
          this.payload.phoneReceiver,
          "Masukkan nomor telepon dengan tipe MSISDN"
        );
      }
      errorPhoneReceiver = ValidateInputPhoneNumber(this.payload.phoneReceiver);
      this.error.successPhoneReceiver = errorPhoneReceiver.success;
      this.error.phoneReceiver = errorPhoneReceiver.error;
      this.error.errorMessagePhoneReceiver = errorPhoneReceiver.message;
    },
    "payload.isCOD": function() {
      let errorPhoneSenderCod = new ErrorPhoneNumber();
      let errorPhoneReceiverCod = new ErrorPhoneNumber();
      if (this.codValidation) {
        errorPhoneSenderCod = ValidateMsisdn(
          this.payload.phoneSender,
          "Masukkan nomor telepon dengan tipe MSISDN"
        );
        errorPhoneReceiverCod = ValidateMsisdn(
          this.payload.phoneReceiver,
          "Masukkan nomor telepon dengan tipe MSISDN"
        );
      }

      errorPhoneSenderCod = this.phoneMustMSISDN();
      errorPhoneReceiverCod = ValidateInputPhoneNumber(
        this.payload.phoneReceiver
      );
      this.error.successPhoneSender = errorPhoneSenderCod.success;
      this.error.phoneSender = errorPhoneSenderCod.error;
      this.error.errorMessagePhoneSender = errorPhoneSenderCod.message;

      this.error.successPhoneReceiver = errorPhoneReceiverCod.success;
      this.error.phoneReceiver = errorPhoneReceiverCod.error;
      this.error.errorMessagePhoneReceiver = errorPhoneReceiverCod.message;
    },
    "payload.isPAD": function() {
      let errorPhoneSenderPad = new ErrorPhoneNumber();
      let errorPhoneReceiverPad = new ErrorPhoneNumber();
      if (this.codValidation) {
        errorPhoneSenderPad = ValidateMsisdn(
          this.payload.phoneSender,
          "Masukkan nomor telepon dengan tipe MSISDN"
        );
        errorPhoneReceiverPad = ValidateMsisdn(
          this.payload.phoneReceiver,
          "Masukkan nomor telepon dengan tipe MSISDN"
        );
      }
      errorPhoneSenderPad = ValidateInputPhoneNumber(this.payload.phoneSender);
      errorPhoneReceiverPad = ValidateInputPhoneNumber(
        this.payload.phoneReceiver
      );
      this.error.successPhoneSender = errorPhoneSenderPad.success;
      this.error.phoneSender = errorPhoneSenderPad.error;
      this.error.errorMessagePhoneSender = errorPhoneSenderPad.message;

      this.error.successPhoneReceiver = errorPhoneReceiverPad.success;
      this.error.phoneReceiver = errorPhoneReceiverPad.error;
      this.error.errorMessagePhoneReceiver = errorPhoneReceiverPad.message;
    }
  }
})
export default class Add extends Vue {
  get isEnableCodBooking() {
    return FlagsMedusa.config_new_cod_booking_enable.isEnabled();
  }

  get maxAddressLength() {
    return FlagsMedusa.config_max_address.getValue();
  }

  get forceRouter() {
    return BookingController.forceRouter;
  }

  get isShowJumbopack() {
    return (
      ((this.formType === "manual" &&
        this.getAccount.accountType === "client") ||
        this.formType === "client") &&
      flags.show_jumbopack_on_booking_page.isEnabled()
    );
  }

  error = {
    sttManual: false,
    productName: false,
    taxNumber: false,
    successPhoneSender: false,
    phoneSender: false,
    phoneCodeSender: false,
    phoneCharMinimalSender: false,
    errorMessagePhoneSender: "",
    successPhoneReceiver: false,
    phoneReceiver: false,
    phoneCodeReceiver: false,
    phoneCharMinimalReceiver: false,
    errorMessagePhoneReceiver: "",
    estimationPrice: false,
    grossWeight: false,
    product: false,
    insurance: false
  };

  // Tooltip
  isOpenTooltipManualSTT = false;
  isOpenTooltipStatusGoods = false;
  isOpenTooltipInsurance = false;
  isOpenTooltipTax = false;
  isHoverDelete = false;
  isOpenTooltipWeight = false;
  isOpenTooltipPiecePerPack = false;
  isOpenTooltipNextCommodity = false;

  cacheIndex = 0;
  origins = [];
  destinations = [];
  destination = "";
  commodity = "";
  sender = "";
  receiver = "";
  commodityName = "";
  isOpenCommodity = false;
  checkedNumberSender = false;
  checkedNumberReceiver = false;
  isStatusGoods = false;
  isOpenSelectInsurance = false;
  isOpenSelectAddressType = false;
  tempIndexToRemove = -1;
  isEditTerminated = false;
  newPath = "";
  answer = false;
  indexVolume = 0;
  division = 6000;
  get listStatusGoodsForIndonesian() {
    return [
      { name: "Pilih status pengiriman yang akan dikirim", value: "" },
      { name: "Ecommerce", value: "ecommerce" },
      { name: "Produk lokal / Batam", value: "batam" },
      { name: "Eks luar Batam / transit", value: "transit" },
      { name: "Salah kirim / return", value: "return" },
      { name: "Personal effect", value: "personal-effect" }
    ];
  }

  get listStatusGoodsForEnglish() {
    return [
      { name: "Select shipment status", value: "" },
      { name: "Retail", value: "retail" },
      { name: "Diplomatic Pouch", value: "diplomatic-pouch" },
      { name: "Personal Effect", value: "personal-effect" },
      { name: "Marketplace", value: "ecommerce" }
    ];
  }

  listKGs: any = [];

  get getTitle() {
    return this.$t("Buat Booking Baru");
  }

  get formType() {
    return this.$route.meta.formType;
  }

  async mounted() {
    this.getInsurancesTypeDelivery();
    await AccountController.checkAccountLocation();
    await this.fetchProductConfigurableRule();
    this.getCustomerList();
    this.getCommodityList();
    this.filterProductsDelivery("");
    this.getClientID();
    BookingController.setDefaultManualBooking();
    BookingController.setForceRouter(false);
    this.resetPageCreateBooking();
    if (this.isFormBookManual && this.getAccount.accountType === "partner") {
      this.fetchListConfigurationCOD();
      this.dfodStatus();
      this.insuranceForClients = "basic";
    }
    this.codStatus(this.formType, this.getAccount.accountType, true);
    this.manualBooking.phoneCodeSender = this.accountIsNonForeign
      ? "+62"
      : "+60";
    this.manualBooking.phoneFlagSender = this.accountIsNonForeign
      ? "indonesia"
      : "malaysia";
    this.setCodDfodClient();
  }

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
    BookingController.removeWeightDimensions();
    this.resetProductUpselling();
    this.manualBooking.destinationCity = "";
    BookingController.setErrorDestination(false);
    BookingController.setProductName("");
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  get isFreeTradeZone() {
    return AccountController.isFreeTradeZone;
  }

  get isErrorSpesificRate() {
    return BookingController.errorSpesificByRate;
  }

  get isErrorAdd() {
    return BookingController.isError;
  }

  get errorCause() {
    return BookingController.isErrorCause;
  }

  get convertedArr() {
    return this.listKGs.map((key: any) => ({
      stt_piece_length: key.dimension.length,
      stt_piece_width: key.dimension.width,
      stt_piece_height: key.dimension.height,
      stt_piece_gross_weight: Number(
        key.grossWeight.toString().replace(",", ".")
      ),
      stt_piece_volume_weight: key.volumeWeight
    }));
  }

  get totalChargeableWeight() {
    let total: any = 0;
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    if (
      this.totalGrossWeight.includes(",") ||
      this.totalVolumeWeight.includes(",")
    ) {
      total = this.compareTotalGrossAndVolume(x, y);
    } else {
      total = `${x > y ? x : y}`;
    }
    return total;
  }

  get productConfigRuleActive() {
    const isRetailAllowed =
      this.isFormBookManual && this.getAccount.accountType === "partner";

    return this.productConfigurationRuleList.findProductConfigRule(
      this.manualBooking.productName,
      this.manualBooking.clientName?.id || this.detailClientID.id,
      isRetailAllowed
    );
  }
  get isCampaign() {
    return this.productConfigRuleActive.isCampaign;
  }

  get isGrossWeight() {
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    return x > y;
  }

  get isVolumeWeight() {
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    return x < y;
  }

  get totalCampaignChargeableWeight() {
    let total: any = 0;
    const x = Number(this.totalGrossWeight.replace(",", "."));
    const y = Number(this.totalVolumeWeight.replace(",", "."));
    if (this.totalGrossWeight || this.totalVolumeWeight) {
      total = totalGrossWeightSikat(
        this.isCampaign,
        x,
        y,
        this.totalGrossWeight,
        this.totalVolumeWeight
      );
    } else {
      total = totalGrossWeightNonSikat(this.isCampaign, x, y);
    }
    return total;
  }

  compareTotalGrossAndVolume(x: number, y: number) {
    let total = 0;
    if (x > y) {
      total = this.totalGrossMoreThanVolume(x, y);
    } else {
      total = this.volumeMoreThanTotalGross(x, y);
    }
    return total;
  }

  totalGrossMoreThanVolume(x: number, y: number) {
    let total = 0;
    const split = this.totalGrossWeight.split(",");
    let result = x - Number(split[0]);
    result = Number(Number.parseFloat(result.toString()).toFixed(2));
    if (x < 1) {
      total = 1;
    } else {
      total =
        result >= this.productConfigRuleActive.parameter
          ? Math.ceil(x)
          : Math.floor(x);
    }

    return total;
  }

  volumeMoreThanTotalGross(x: number, y: number) {
    let total = 0;
    const split = this.totalVolumeWeight.split(",");
    let result = y - Number(split[0]);
    result = Number(Number.parseFloat(result.toString()).toFixed(2));
    if (y < 1) {
      total = 1;
    } else {
      total =
        result >= this.productConfigRuleActive.parameter
          ? Math.ceil(y)
          : Math.floor(y);
    }

    return total;
  }

  get payload(): any {
    return {
      ...this.payloadSectionOne,
      ...this.payloadSectionTwo
    };
  }

  get payloadSectionOne() {
    return {
      sttManual: this.manualBooking.sttManual,
      referenceExternal: !this.manualBooking.referenceExternal
        ? ""
        : this.manualBooking.referenceExternal,
      productName: this.manualBooking.productName,
      originCity:
        this.origin.cityCode || this.manualBooking.originCity.cityCode,
      originDistrict: this.origin.code || this.manualBooking.originCity.code,
      destinationCity: this.manualBooking.destinationCity.cityCode,
      destinationDistrict: this.manualBooking.destinationCity.code,
      phoneSender: `${this.manualBooking.phoneCodeSender.substring(1)}${
        this.manualBooking.phoneSender
      }`,
      phoneCodeSender: this.manualBooking.phoneCodeSender,
      phoneFlagSender: this.manualBooking.phoneFlagSender,
      phoneReceiver: `${this.manualBooking.phoneCodeReceiver.substring(1)}${
        this.manualBooking.phoneReceiver
      }`,
      phoneCodeReceiver: this.manualBooking.phoneCodeReceiver,
      phoneFlagReceiver: this.manualBooking.phoneFlagReceiver,
      sender:
        typeof this.manualBooking.sender === "object"
          ? this.manualBooking.sender.customerName
          : this.manualBooking.sender,
      receiver:
        typeof this.manualBooking.receiver === "object"
          ? this.manualBooking.receiver.customerName
          : this.manualBooking.receiver,
      addressSender: this.manualBooking.addressSender,
      addressReceiver: this.manualBooking.addressReceiver
    };
  }

  get payloadSectionTwo() {
    return {
      sttGrossWeight: Number(
        this.totalGrossWeight.toString().replace(",", ".")
      ),
      sttVolumeWeight: Number(
        this.totalVolumeWeight.toString().replace(",", ".")
      ),
      sttChargeableWeight: this.isCampaign
        ? Number(this.totalCampaignChargeableWeight)
        : Number(this.totalChargeableWeight.toString().replace(",", ".")),
      addressReceiverType: this.manualBooking.addressReceiverType,
      commodityCode: this.manualBooking.commodityCode.code,
      insurance: this.manualBooking.insurance,
      statusGoods: this.manualBooking.statusGoods,
      estimationPrice: Number(
        this.manualBooking.estimationPrice
          ?.replace(/[.]/g, "")
          ?.replaceAll(",", ".")
      ),
      taxNumber: this.manualBooking.taxNumber,
      isWoodpack: this.manualBooking.isWoodpack,
      isCOD: this.codValueForEndpointBookingManual,
      isDFOD: this.dfodValueForEndpointBookingManual,
      isCODClient:
        this.deliveryFeeTypeClient === "dfod"
          ? true
          : this.deliveryFeeTypeClient === "cod",
      isDFODClient: this.deliveryFeeTypeClient === "dfod",
      isPAD: this.manualBooking.isPAD,
      codAmount:
        this.manualBooking.isCOD || this.deliveryFeeTypeClient === "cod"
          ? Number(this.manualBooking.codAmount.toString().replace(/[.]/g, ""))
          : 0,
      isSaveSender: this.manualBooking.isSaveSender,
      isSaveReceiver: this.manualBooking.isSaveReceiver,
      sttPieces: this.convertedArr,
      isDO: this.isDO,
      isMixpack: this.isMixpack,
      sttPiecePerPack: Number(this.manualBooking.piecePerPack),
      sttNextCommodity: this.manualBooking.nextCommodity,
      clientName: this.manualBooking.clientName,
      postalCodeDestination: this.manualBooking.postalCodeDestination?.id,
      sttAttachFiles: this.additionalInfo.files.filter((item: string) => item),
      sttCommodityDetail: this.additionalInfo.commodity,
      sttWeightAttachFiles: this.fileAdjustmentsFilter
    };
  }

  disabledInsurance = false;
  handleInsurance(val: any) {
    const result = handleInsuranceFromEstimationPrice(
      val,
      this.deliveryFeeType === "non-cod"
    );
    this.disableInsurance(val);

    if (this.isFormBookManual) {
      this.conditionInsuranceForManualBook(result, val);
    } else {
      this.conditionInsuranceForClientBook(result);
    }
  }

  conditionInsuranceForManualBook(result: string, val = "") {
    if (result === "basic_and_disable_select_insurance") {
      this.disabledInsurance = true;
      this.manualBooking.insurance = "basic";
      this.insuranceForClients = "basic";
      this.insuranceCondition = 6;
    }
    // dfod and goods price more than equal 5 milion
    if (+val.replace(/\./g, "") >= 5000000 && this.deliveryFeeType === "dfod") {
      this.manualBooking.insurance = "basic";
      this.insuranceForClients = "basic";
    }
  }

  conditionInsuranceForClientBook(result: string) {
    if (result === "basic") {
      this.manualBooking.insurance = "basic";
      this.insuranceForClients = "basic";
    } else if (result === "basic_and_disable_select_insurance") {
      this.disabledInsurance = true;
      this.manualBooking.insurance = "basic";
      this.insuranceForClients = "basic";
    } else {
      this.manualBooking.insurance = "free";
      this.insuranceForClients = "free";
    }
  }

  disableInsurance(val = "") {
    if (this.disableInsuranceCOD || this.disableInsuranceDFOD) {
      this.disabledInsurance = true;
    } else {
      // dfod and goods price more than equal 5 milion
      this.disabledInsurance =
        +val.replace(/\./g, "") >= 5000000 && this.deliveryFeeType === "dfod";
    }
  }

  get disableInsuranceCOD() {
    return (
      this.listCodActive[0]?.ccoIsInsurance && this.deliveryFeeType === "cod"
    );
  }

  get disableInsuranceDFOD() {
    return (
      this.listDfodActive[0]?.ccoIsInsurance && this.deliveryFeeType === "dfod"
    );
  }

  addKGs() {
    const weight = (10 * 10 * 10) / this.division;
    const splitVolumeWeight = weight
      .toString()
      .replace(".", ",")
      .split(",");
    const front = splitVolumeWeight[0];
    const behind = splitVolumeWeight[1]
      ? "," + splitVolumeWeight[1].slice(0, 2)
      : "";
    this.listKGs.push({
      grossWeight: '0',
      dimension: {
        length: 10,
        width: 10,
        height: 10
      },
      volumeWeight: weight,
      convertVolumeWeight: front + behind,
      errorHws: false
    });
  }

  onCloseCommodity() {
    this.isOpenCommodity = false;
  }

  getCommodityList() {
    CommodityController.getCommodityList(new RequestListCommodity());
  }

  fetchCommodityList = debounce((search: string) => {
    CommodityController.getCommodityList(
      new RequestListCommodity({
        search: search
      })
    );
  }, 250);
  onFocusCommodityList() {
    this.setFieldIndex(11);
    if (!this.manualBooking.commodityCode) {
      this.getCommodityList();
    }
  }

  get isErrorCity() {
    return (
      this.manualBooking.destinationCity?.status === "INACTIVE" ||
      this.manualBooking.originCity?.status === "INACTIVE"
    );
  }

  get checkIsDOForClientManual() {
    return !(this.detailClientID.clientIsDo && !this.payload.referenceExternal);
  }

  get isErrorCommodity() {
    return this.manualBooking.commodityCode?.status === "inactive";
  }

  get isFormValid() {
    return (
      this.formValidationShipmentDetail &&
      this.formValidationShipmentGood &&
      this.fomValidationOthers &&
      this.isFormValidationError
    );
  }

  get formValidationShipmentDetail() {
    return (
      this.payload.sender &&
      this.payload.receiver &&
      this.payload.phoneSender &&
      this.payload.phoneReceiver &&
      this.payload.addressSender &&
      this.payload.addressReceiver &&
      this.payload.addressReceiverType
    );
  }

  get formValidationShipmentGood() {
    return (
      this.payload.productName &&
      this.payload.commodityCode &&
      this.payload.insurance &&
      this.payload.estimationPrice &&
      this.payload.sttGrossWeight &&
      this.payload.sttChargeableWeight &&
      this.checkIsDOForClientManual
    );
  }

  get fomValidationOthers() {
    return (
      this.payload.destinationCity &&
      this.payload.destinationDistrict &&
      this.checkIntersection() &&
      this.isFtzRequiredForm &&
      this.validationVendorNinja
    );
  }

  get isFormValidationError() {
    return (
      !this.error.product &&
      this.errorPhoneSender.success &&
      !this.errorPhoneSender.errorPhoneCode &&
      this.errorPhoneReceiver.success &&
      !this.errorPhoneReceiver.errorPhoneCode &&
      !this.error.insurance &&
      !this.error.sttManual &&
      !this.errorEstimationPrice
    );
  }

  get errorPhoneNumber() {
    if (this.accountIsNonForeign) {
      return (
        this.errorPhoneSender.success &&
        !this.errorPhoneSender.errorPhoneCode &&
        this.errorPhoneReceiver.success &&
        !this.errorPhoneReceiver.errorPhoneCode
      );
    } else {
      return false;
    }
  }

  get validationVendorNinja() {
    let result = true;
    if (this.isVendorNinjaDestination && this.listKGs.length > 1) {
      result = false;
    }
    return result;
  }

  checkIntersection() {
    const result = true;
    for (const el of this.convertedArr) {
      if (
        this.piecesAreZero(
          el.stt_piece_gross_weight,
          el.stt_piece_width,
          el.stt_piece_length,
          el.stt_piece_height
        )
      ) {
        return false;
      }
    }
    return result;
  }

  piecesAreZero(weight: number, width: number, length: number, height: number) {
    return weight === 0 || width === 0 || length === 0 || height === 0;
  }

  // error indicator mandatory
  currentFieldIndex = 0;
  setFieldIndex(index: number) {
    if (index > this.currentFieldIndex) {
      this.currentFieldIndex = index;
    }
  }
  get errorMandatory() {
    return [
      ...this.errorMandatoryDetailDelivery,
      ...this.errorMandatoryDetailGood
    ];
  }

  get errorMandatoryDetailDelivery() {
    return [
      this.errorMandatoryClientColumn,
      this.errorProductcolumn,
      this.errorMandatoryExternalReffColumn,
      !this.payload.sender && this.currentFieldIndex > 3,
      !this.payload.receiver && this.currentFieldIndex > 4,
      !this.manualBooking.phoneSender && this.currentFieldIndex > 5,
      !this.manualBooking.phoneReceiver && this.currentFieldIndex > 6,
      !this.payload.addressSender && this.currentFieldIndex > 7,
      !this.payload.addressReceiver && this.currentFieldIndex > 8,
      this.errorMandatoryDestinationColumn,
      !this.payload.destinationDistrict && this.currentFieldIndex > 10
    ];
  }

  get errorProductcolumn() {
    return !this.payload.productName && this.currentFieldIndex > 1;
  }

  get errorMandatoryClientColumn() {
    return (
      !this.payload.clientName &&
      (this.isFormBookClient || this.getAccount.accountType === "client") &&
      this.currentFieldIndex > 0
    );
  }

  get errorMandatoryExternalReffColumn() {
    return (
      !this.payload.referenceExternal &&
      ((this.isFormBookClient && this.isClientDO) ||
        (this.getAccount.accountType === "client" && this.isDO)) &&
      this.currentFieldIndex > 2
    );
  }

  get errorMandatoryDestinationColumn() {
    return (
      !this.manualBooking.originCity?.code &&
      this.getAccount.accountType === "internal" &&
      this.currentFieldIndex > 9
    );
  }

  get errorMandatoryDetailGood() {
    const newFiles = this.additionalInfo.files.filter((item: string) => item);
    return [
      !this.payload.commodityCode && this.currentFieldIndex > 11,
      false,
      !this.payload.insurance && this.currentFieldIndex > 13,
      !this.payload.estimationPrice && this.currentFieldIndex > 14,
      !this.manualBooking.codAmount && this.currentFieldIndex > 15,
      false,
      this.errorManadatoryShipmentStatus,
      this.errorMandatoryPiecePerPack,
      this.errorMandatoryNextCommodity,
      this.errorMandatoryAdditionalInfo,
      newFiles.length < 3 &&
        this.isProductInterpack &&
        this.currentFieldIndex > 21
    ];
  }

  get errorManadatoryShipmentStatus() {
    return (
      (!this.payload.statusGoods || !this.statusGoodValueForclients) &&
      (this.isFtzAccount || this.isFormBookClient) &&
      this.currentFieldIndex > 17
    );
  }

  get errorMandatoryPiecePerPack() {
    return (
      !this.payload.sttPiecePerPack &&
      this.isFtzAccount &&
      this.currentFieldIndex > 18
    );
  }

  get errorMandatoryNextCommodity() {
    return (
      !this.payload.sttNextCommodity &&
      this.isFtzAccount &&
      this.currentFieldIndex > 19
    );
  }

  get errorMandatoryAdditionalInfo() {
    return (
      !this.additionalInfo.commodity &&
      this.isProductInterpack &&
      this.currentFieldIndex > 20
    );
  }

  get manualBooking(): any {
    return BookingController.manualBooking;
  }

  get commodities() {
    return CommodityController.commodities.data.map(item => {
      return {
        id: item.commodity_id,
        name: this.accountIsNonForeign
          ? item.commodity_name
          : item.commodity_name_en,
        code: item.commodity_code,
        status: item.commodity_status
      };
    });
  }

  get isLoadingCommodity() {
    return CommodityController.isLoading;
  }

  get arrGrossWeight() {
    return this.listKGs.map((key: any) => {
      return Number(key.grossWeight.toString().replace(/,/g, "."));
    });
  }

  get validateGrossWeightJumbopack() {
    const value = this.totalChargeableWeight < 30;
    return this.manualBooking.productName === "JUMBOPACK" && !!value;
  }

  get totalGrossWeight() {
    let indicator = 0;
    this.arrGrossWeight.map((key: any) => {
      indicator += key;
      return indicator;
    });
    const split = indicator
      .toString()
      .replace(".", ",")
      .split(",");
    const front = split[0];
    const behind = split[1] ? "," + split[1].slice(0, 2) : "";
    return front + behind;
  }

  get arrVolumeWeight() {
    return this.listKGs.map((key: any) => {
      key.volumeWeight = Number(key.volumeWeight.toString().replace(/,/g, "."));
      return key.volumeWeight;
    });
  }

  get totalVolumeWeight() {
    let indicator = 0;
    this.arrVolumeWeight.map((key: any) => {
      indicator += key;
      return indicator;
    });
    const split = indicator
      .toString()
      .replace(".", ",")
      .split(",");
    const front = split[0];
    const behind = split[1] ? "," + split[1].slice(0, 2) : "";
    return front + behind;
  }

  removeKGs(index: number) {
    this.isHoverDelete = false;
    this.tempIndexToRemove = index;
    if (this.tempIndexToRemove > -1) {
      this.listKGs.splice(this.tempIndexToRemove, 1);
      this.tempIndexToRemove = -1;
    }

    this.onSetErrorHws();
  }

  isHoverEnter(indexParam: number) {
    this.isHoverDelete = true;
    this.cacheIndex = indexParam;
  }

  formatted(value: string) {
    return formattedNPWP(value.replace(/[\D]+/g, ""));
  }

  calculationVolumeWeight(index: number) {
    this.indexVolume = index;
    const dimension =
      this.listKGs[index].dimension.length *
      this.listKGs[index].dimension.width *
      this.listKGs[index].dimension.height;

    const volume = volumeWeightForProduct(this.manualBooking.productName);
    this.listKGs[index].volumeWeight = dimension / volume;
    this.division = volume;

    const split = this.listKGs[index].volumeWeight
      .toString()
      .replace(".", ",")
      .split(",");
    const front = split[0];
    const behind = split[1] ? "," + split[1].slice(0, 2) : "";
    this.listKGs[index].convertVolumeWeight = front + behind;
  }

  onChangeDimension(obj: any) {
    this.listKGs[0].dimension.length = obj.length;
    this.listKGs[0].dimension.width = obj.width;
    this.listKGs[0].dimension.height = obj.height;
  }

  isMaxLengthGrossWeight: any = null;
  formatFloat(value: string) {
    const regex = value
      .replace(/[^0-9.,]/, "")
      .replace(/\./g, ",")
      .replace(/,,/g, ",")
      .replace(/,\./g, ",")
      .replace(/,(\d+),/g, ",$1");

    this.isMaxLengthGrossWeight = maxLengthWeight(regex);
    return regex;
  }

  validatePhoneSender(value: string) {
    if (!value) {
      return;
    }
    const phoneArray = value.split("");
    if (this.detectPhoneNumber(phoneArray) && phoneArray.length > 14) {
      const newPhone = value.slice(0, -1);
      BookingController.setPhoneSender(newPhone);
    } else {
      BookingController.setPhoneSender(value);
    }
  }

  validatePhoneReceiver(value: string) {
    if (!value) {
      return;
    }
    const phoneArray = value.split("");
    if (this.detectPhoneNumber(phoneArray) && phoneArray.length > 14) {
      const newPhone = value.slice(0, -1);
      BookingController.setPhoneReceiver(newPhone);
    } else {
      BookingController.setPhoneReceiver(value);
    }
  }

  detectPhoneNumber(phoneArray: Array<string>) {
    return phoneArray.find(
      () => phoneArray[0] === "0" && phoneArray[1] === "8"
    );
  }

  validateTaxNumber(value: string) {
    this.error.taxNumber = !/[0-9-.]+/.test(value);
    if (value.length < 20) {
      this.error.taxNumber = true;
    }
  }

  handleErrorTaxNumber() {
    if (!this.manualBooking.taxNumber) {
      this.error.taxNumber = false;
    }
  }

  get prefixCurrency() {
    return this.accountIsNonForeign ? "IDR" : "RM";
  }

  formatEstimationPrice(value: string) {
    return this.accountIsNonForeign
      ? this.formatRupiah(value)
      : this.formatRinggit(value);
  }

  formatRupiah(value: string) {
    return formatInputMoney(value.replace(/[\D]+/g, ""));
  }

  formatRinggit(value: any) {
    return formatInputMoneyWithDecimal(value);
  }

  formatPiecePerPack(value: string) {
    return value.replace(/[\D]+/g, "").replace(/\D|^0+/g, "");
  }

  // route navigation
  goBack() {
    this.$router.push("/shipment/booking");
  }
  onOpenSelect() {
    this.isStatusGoods = true;
  }

  onCloseSelect() {
    this.isStatusGoods = false;
  }

  onSelectStatus(key: string, value: string) {
    BookingController.setStatusGoods(value);
    this.statusGoodForClients = value;
  }

  filterProductsDelivery = debounce((search: string) => {
    BookingController.getProductsDelivery({
      search: search,
      page: 1,
      limit: 10,
      status: "active"
    });
  }, 250);

  get productsDelivery() {
    return BookingController.productsDelivery.data.map((e: any) => ({
      id: e.id,
      name: e.name,
      status: e.status
    }));
  }

  get isLoadingProduct() {
    return BookingController.loadingProductsDelivery;
  }

  isProductSelected = false;
  async onSelectProduct(obj: any) {
    if (obj) {
      if (obj.status === "ACTIVE") {
        if (
          this.manualBooking.productName !== "INTERPACK" &&
          obj.name === "INTERPACK"
        ) {
          this.listKGs = [this.listKGs[0]];
        }

        this.isProductSelected = true;
        BookingController.setProductName(obj.name);
        for (let i = 0; i < this.listKGs.length; i++) {
          this.calculationVolumeWeight(i);
        }
        this.validateSttManual(this.manualBooking.sttManual);
        this.isClientDO = this.statusCODClient;
        this.resetZipCode();
        this.codStatus(this.formType, this.getAccount.accountType, true);
        this.dfodStatus();
      } else {
        this.error.product = true;
      }
      this.setStatusInsurance(obj.name, obj.status === "ACTIVE");
      this.callZiCode();
    } else {
      this.isProductSelected = false;
      this.isClientDO = true;
      this.manualBooking.sttManual = "";
    }

    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(false);
    }
    if (
      this.manualBooking.productName &&
      this.manualBooking.commodityCode.code
    ) {
      await this.getConfigHws(obj.name, this.manualBooking.commodityCode.code);
      this.onSetErrorHws();
    }
  }

  resetZipCode() {
    if (this.accountIsNonForeign) {
      BookingController.setPostalCodeDestination("");
    }
  }

  get zipCodeForDestinationAndProduct() {
    return (
      this.manualBooking.destinationCity &&
      this.manualBooking.productName === "INTERPACK"
    );
  }

  async callZiCode() {
    if (this.zipCodeForDestinationAndProduct && this.accountIsNonForeign) {
      this.callMainZipCode();
    }
  }

  async callZipCodeForForeign() {
    if (this.manualBooking.destinationCity && !this.accountIsNonForeign) {
      this.callMainZipCode();
    }
  }

  async callMainZipCode() {
    const responseDistrictData = await LocationController.getDetailDistrict({
      id: this.manualBooking.destinationCity.id,
      notSaveDetail: true
    });
    this.postalCodeDestinationOptions = responseDistrictData.originListZipCode.map(
      (item: any) => {
        return {
          id: item,
          name: item
        };
      }
    );
  }

  // address type types
  get listAddressType() {
    const defaultChoices: any = [
      {
        name: this.$t("bookingShipment.column.office"),
        value: "office"
      },
      {
        name: this.$t("bookingShipment.column.home"),
        value: "home"
      }
    ];

    return defaultChoices;
  }

  onOpenSelectAddressType() {
    this.isOpenSelectAddressType = true;
  }

  onCloseSelectAddressType() {
    this.isOpenSelectAddressType = false;
  }

  isErrorSelectAddressType = false;
  onSelectAddressType(name: string, value: string) {
    this.manualBooking.addressReceiverType = value;
    if (!value) {
      this.isErrorSelectAddressType = true;
    } else {
      this.isErrorSelectAddressType = false;
    }
  }

  // insurance types
  listInsurance: any = [
    {
      name: "",
      value: ""
    }
  ];
  async getInsurancesTypeDelivery() {
    await BookingController.getInsuranceTypesDelivery({
      insuranceConfig: 2
    });

    this.listInsurance = BookingController.insuranceTypeDelivery.data.map(
      (e: InsuranceTypeDeliveryData) => {
        return {
          name: e.name,
          value: e.value,
          status: e.status,
          cost: e.cost,
          commissionPos: e.commissionPos
        };
      }
    );
  }

  onOpenSelectInsurance() {
    this.isOpenSelectInsurance = true;
  }

  onCloseSelectInsurance() {
    this.isOpenSelectInsurance = false;
  }

  onSelectStatusInsurance(name: string, value: string) {
    this.manualBooking.insurance = value;
    this.insuranceForClients = value;

    try {
      const insurance = this.listInsurance.find(
        (item: any) => item.value == value
      );
      this.error.insurance = insurance?.status === "Inactive";
    } catch (e) {
      this.error.insurance = false;
    }

    this.flipInsurancesProfit();
  }

  changeToInsuranceProfit() {
    const isNonCodValidate = this.deliveryFeeType === "non-cod";
    const isDfodValidate = this.deliveryFeeType === "dfod";
    this.checkProfitInsurance(
      Number(
        this.manualBooking.estimationPrice
          .replace(/[.]/g, "")
          .replaceAll(",", ".")
      ),
      isNonCodValidate,
      isDfodValidate
    );
  }

  get titleErrorspesification() {
    return BookingController.titleErrorspesification;
  }

  closeModalError() {
    BookingController.setError(false);
    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(true);
    }
  }

  updateStatusErrorEmbargo(status: boolean) {
    this.errorEmbargo.originCity = status;
    this.errorEmbargo.destinationCity = status;
    this.errorEmbargo.productType = status;
    this.errorEmbargo.comodity = status;
    this.errorEmbargo.weightStatus = status;
    this.errorEmbargo.lengthStatus = status;
  }

  //customer sender receiver
  get senders() {
    return this.getPhoneNumbers;
  }
  get receivers() {
    return this.getPhoneNumbers;
  }

  get getPhoneNumbers() {
    const list = BookingController.customerList.data;
    if (list.length !== 0) {
      return BookingController.customerList.data.map(item => {
        return {
          ...item,
          customerPhoneNumber: `${item.customerPhoneNumber}`
        };
      });
    } else {
      return list;
    }
  }

  getCustomerList() {
    BookingController.getCustomerList({ search: "", cache: true });
  }
  setNameCustomer(search: string, type: string) {
    if (type === "senders") {
      BookingController.setNameSender(search); // set payload even if not choosing
    }
    if (type === "receivers") {
      BookingController.setNameReceiver(search); // set payload even if not choosing
    }
    if (search.length === 0) {
      return;
    }
    this.fetchCustomer(search);
  }
  setCustomer(search: string, type: string) {
    if (type === "senders") {
      BookingController.setNameSender(search); // set payload even if not choosing
    }
    if (type === "receivers") {
      BookingController.setNameReceiver(search); // set payload even if not choosing
    }
  }
  fetchCustomer = debounce((search: string) => {
    if (search.length > 2) {
      BookingController.getCustomerList({ search: search, cache: true });
    }
  }, 1000);
  get isLoadingCustomer() {
    return BookingController.isLoadingCustomer;
  }

  onChooseSenders(item: any, isChoosen = true) {
    if (!item) {
      return false;
    }
    item = {
      ...item,
      customerPhoneCode: codePhoneCountry(item.customerPhoneNumber).code,
      customerPhoneflag: codePhoneCountry(item.customerPhoneNumber).flag,
      customerPhoneNumber: codePhoneCountry(item.customerPhoneNumber).number
    };

    BookingController.setDataSender(item);
    this.error.phoneCharMinimalSender =
      item.customerPhoneNumber.length < 5 && isChoosen;
    this.validatePhoneSender(item.customerPhoneNumber);
  }
  onChooseReceivers(item: any, isChoosen = true) {
    if (!item) {
      return false;
    }
    item = {
      ...item,
      customerPhoneCode: codePhoneCountry(item.customerPhoneNumber).code,
      customerPhoneflag: codePhoneCountry(item.customerPhoneNumber).flag,
      customerPhoneNumber: codePhoneCountry(item.customerPhoneNumber).number
    };

    BookingController.setDataReceiver(item);
    this.error.phoneCharMinimalReceiver =
      item.customerPhoneNumber.length < 5 && isChoosen;
    this.validatePhoneReceiver(item.customerPhoneNumber);
  }

  onSaveCustomer = debounce((event: any, type: string) => {
    if (!event.target.checked) {
      return;
    }
    if (type === "sender") {
      if (
        this.manualBooking.sender &&
        this.manualBooking.phoneSender &&
        this.manualBooking.phoneCodeSender
      ) {
        BookingController.addCustomer({
          name:
            typeof this.manualBooking.sender === "object"
              ? this.manualBooking.sender.customerName
              : this.manualBooking.sender,
          phone: `${this.manualBooking.phoneCodeSender.substring(1)}${
            this.manualBooking.phoneSender
          }`,
          address: this.manualBooking.addressSender
        });
      }
    } else if (type === "receiver") {
      if (
        this.manualBooking.receiver &&
        this.manualBooking.phoneReceiver &&
        this.manualBooking.phoneCodeReceiver
      ) {
        BookingController.addCustomer({
          name:
            typeof this.manualBooking.receiver === "object"
              ? this.manualBooking.receiver.customerName
              : this.manualBooking.receiver,
          phone: `${this.manualBooking.phoneCodeReceiver.substring(1)}${
            this.manualBooking.phoneReceiver
          }`,
          address: this.manualBooking.addressReceiver
        });
      }
    }
  }, 1000);

  get accountRefId(): number {
    return AccountController.accountData.account_type_detail.id;
  }

  get isClientPartner(): boolean {
    return (
      this.manualBooking.sttManual &&
      this.manualBooking.sttManual.accountRefId !== this.accountRefId
    );
  }

  get origin() {
    const detail = LocationController.districtDetail;
    return {
      id: detail.id,
      name: `${detail.name}, ${detail.cityName}`,
      code: `${detail.code}`,
      cityCode: `${detail.cityCode}`
    };
  }

  // Destination City
  get errorDestinationCity() {
    return BookingController.errorDestination;
  }

  get isVendorNinjaDestination() {
    return BookingController.isVendorNinjaDestination;
  }

  async handleErrorDestinationCity() {
    BookingController.setProductName("");
    BookingController.setErrorDestination(false);
    this.resetPhoto();

    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(false);
    }
    if (this.isVendorNinjaDestination) {
      this.setIsVendorNinjaDestination(false);
    }

    this.checkIfCod(
      this.manualBooking.destinationCity?.isCod === "yes",
      this.isClientCOD
    );

    this.callZiCode();
    this.callZipCodeForForeign();
    this.pickProductUpselling(this.manualBooking.destinationCity);
  }

  loadUpsellingProduct() {
    const visiblePricePosterProduct =
      this.isFormBookManual && this.getAccount.accountType === "partner";
    const renderPoster: any = this.$refs.renderPoster;
    renderPoster?.getPosterProduct(
      "",
      this.payload.originDistrict,
      this.payload.destinationDistrict,
      visiblePricePosterProduct,
      this.clientlientIdForProductUpselling.accountRefId,
      this.clientlientIdForProductUpselling.accountType
    );
  }

  pickProductUpselling(val: any) {
    if (val) {
      this.loadUpsellingProductByCondition();
    } else {
      this.resetProductUpselling();
    }
  }

  loadUpsellingProductByCondition() {
    this.loadUpsellingProductForManualBook();
    this.loadUpsellingProductForClientBook();
  }

  loadUpsellingProductForManualBook() {
    if (
      this.isFormBookManual &&
      this.OriginAndDestinationFullfilledForManualBook
    ) {
      this.loadUpsellingProduct();
    }
  }

  get OriginAndDestinationFullfilledForManualBook() {
    return this.origin.id && this.manualBooking.destinationCity;
  }

  get isFormBookManual() {
    return this.formType === "manual";
  }

  loadUpsellingProductForClientBook() {
    if (this.isFormBookClient && this.fullFilledUpsellingProductForClient) {
      this.loadUpsellingProduct();
    }
  }

  get originFullfilledForManualBook() {
    return this.manualBooking.originCity || this.origin.id;
  }

  get clientNameAndDestinationFullfilledForManualBook() {
    return this.manualBooking.clientName && this.manualBooking.destinationCity;
  }

  get fullFilledUpsellingProductForClient() {
    return (
      this.originFullfilledForManualBook &&
      this.clientNameAndDestinationFullfilledForManualBook
    );
  }

  get isFormBookClient() {
    return this.formType === "client";
  }

  resetProductUpselling() {
    const renderPoster: any = this.$refs.renderPoster;
    renderPoster?.resetAllProduct();
  }

  destinationCityList: Array<any> = [];
  onFocusDestinationCity() {
    if (
      !this.destinationCityList.length ||
      !this.manualBooking.destinationCity
    ) {
      LocationController.setLoadingDistrict(true);
      this.fetchDestinationCity("");
    }
    this.setFieldIndex(10);
  }

  fetchDestinationCity = debounce(async (search: string) => {
    const typeAccuracyAllowed = true;
    const response = await LocationController.getDistrictList({
      search: search,
      status: "",
      page: 1,
      limit: typeAccuracyAllowed ? 50 : 10,
      excludeCountry:
        this.manualBooking.productName === "INTERPACK" ? "ID" : "",
      type: this.districtType,
      cache: true
    });
    this.destinationCityList = this.filterDistrict(response);
  }, 250);

  get districtType() {
    let destinationType = "";
    if (this.getAccount.accountType === "client") {
      if (this.isDO || this.isClientDO) {
        destinationType = "within-city,forward-area";
      }
    }

    return destinationType;
  }

  setIsVendorNinjaDestination(val: boolean) {
    BookingController.setIsVendorNinjaDestination(val);
  }

  checkVendorDestinationDistrict() {
    const clientType = this.vendorTypeForDestinationDistrict;
    const vendorCode = this.vendorCodeForDestinationDistrict;

    if (clientType && vendorCode) {
      if (this.listKGs.length >= 1) {
        this.setIsVendorNinjaDestination(true);
      }
    } else {
      this.setIsVendorNinjaDestination(false);
    }

    return this.isVendorNinjaDestination;
  }

  get vendorTypeForDestinationDistrict() {
    return (
      this.manualBooking.destinationCity?.type?.toLowerCase() === "vendor" ||
      this.manualBooking.destinationCity?.type?.toLowerCase() ===
        "vendor_lanjutan"
    );
  }

  get vendorCodeForDestinationDistrict() {
    return (
      this.manualBooking.destinationCity?.vendor_code?.toLowerCase() ===
        "ninja" ||
      this.manualBooking.destinationCity?.vendor_code?.toLowerCase() === "nx"
    );
  }

  get isLoadingDestinationCity() {
    return LocationController.isLoadingDistrict;
  }

  get disableSaveSender() {
    return (
      !this.manualBooking.sender ||
      !this.manualBooking.phoneSender ||
      !this.manualBooking.phoneCodeSender ||
      !this.manualBooking.addressSender ||
      this.error.phoneSender ||
      this.error.phoneCodeSender
    );
  }

  get disableSaveReceiver() {
    return (
      !this.manualBooking.receiver ||
      !this.manualBooking.phoneReceiver ||
      !this.manualBooking.phoneCodeReceiver ||
      !this.manualBooking.addressReceiver ||
      this.error.phoneReceiver ||
      this.error.phoneCodeReceiver
    );
  }

  get accountRefAndCommodity() {
    return {
      accountRefId: this.manualBooking.sttManual.id,
      commodityId: this.manualBooking.commodityCode.id
    };
  }

  isSubmit = false;

  get isValidPostalCodeDestination() {
    if (this.manualBooking.productName === "INTERPACK") {
      return !!this.manualBooking.postalCodeDestination?.id;
    }
    return true;
  }

  get isDisabledButton() {
    return this.isFormBookManual
      ? this.disableButtonBookManual
      : this.disableButtonClient;
  }

  get disableButtonBookManual() {
    return (
      !this.isFormValid ||
      this.validateTotalGrossWeight ||
      this.validateTotalVolumeWeight ||
      this.isErrorCity ||
      this.isErrorCommodity ||
      this.validateGrossWeightJumbopack ||
      this.isShipperAndConsigneeAddressIdentical ||
      !this.isValidPostalCodeDestination
    );
  }

  get disableButtonClient() {
    return (
      !this.isFormValidClient ||
      this.validateTotalGrossWeight ||
      this.validateTotalVolumeWeight ||
      this.validateGrossWeightJumbopack ||
      this.isErrorCity ||
      this.isErrorCommodity ||
      this.isShipperAndConsigneeAddressIdentical ||
      !this.isValidPostalCodeDestination
    );
  }

  get urlVersion() {
    return isUrlUpdateVersion("customer");
  }

  onEnterBooking(event: any) {
    this.isSubmit = false;
    if (event.key === "Enter") {
      if (this.isDisabledButton) {
        this.setFieldIndex(19);
        return;
      }
      this.enterbookingSuccess();
    }
  }

  enterbookingSuccess() {
    if (!this.isSubmit && !this.loadingModal) {
      if (!this.openSuccess) {
        this.booking();
        return;
      }
    }
  }

  get loadingModal() {
    return MainAppController.loadingModal;
  }

  created() {
    window.addEventListener("keydown", this.onEnterBooking);
  }

  unmounted() {
    BookingController.setDefaultManualBooking();
    window.removeEventListener("keydown", this.onEnterBooking);
  }

  booking() {
    this.isSubmit = true;
    if (this.formType !== "client") {
      this.submitBookManualParam();
    } else {
      this.submitBookCoorporateParam();
    }
  }

  async submitBookManualParam() {
    try {
      const createbooking: ResponsePayload = await BookingController.addManualBooking(
        this.payloadBookingManual
      );

      await BookingController.onSuccessBooking({
        sttNumber: createbooking.sttNumber,
        sttId: createbooking.sttId
      });
    } catch (error) {
      BookingController.generateErrorBooking({
        err: error,
        payload: this.payloadBookingManual,
        errorFor: "book",
        calllFunctionAgain: this.submitBookManualParam
      });
    } finally {
      BookingController.finalCreateBooking();
    }
  }

  get payloadBookingManual() {
    return new AddManualBooking({
      sttManual: this.payload.sttManual,
      referenceExternal: this.payload.referenceExternal,
      productName: this.payload.productName,
      originCity: this.payload.originCity,
      originDistrict: this.payload.originDistrict,
      destinationCity: this.payload.destinationCity,
      destinationDistrict: this.payload.destinationDistrict,
      phoneSender: this.payload.phoneSender,
      phoneReceiver: this.payload.phoneReceiver,
      sender: this.payload.sender,
      receiver: this.payload.receiver,
      addressSender: this.payload.addressSender,
      addressReceiver: this.payload.addressReceiver,
      addressReceiverType: this.payload.addressReceiverType,
      sttGrossWeight: this.payload.sttGrossWeight,
      sttVolumeWeight: this.payload.sttVolumeWeight,
      sttChargeableWeight: this.payload.sttChargeableWeight,
      commodityCode: this.payload.commodityCode,
      insurance: this.payload.insurance,
      statusGoods: this.payload.statusGoods,
      estimationPrice: this.payload.estimationPrice,
      taxNumber: this.payload.taxNumber,
      isWoodpack: this.payload.isWoodpack,
      isCOD: this.payload.isCOD,
      isDFOD: this.payload.isDFOD,
      sttAttachFiles: this.payload.sttAttachFiles,
      sttCommodityDetail: this.payload.sttCommodityDetail,
      isSaveSender: this.payload.isSaveSender,
      isSaveReceiver: this.payload.isSaveReceiver,
      sttPieces: this.payload.sttPieces,
      isDO: this.payload.isDO,
      isMixpack: this.payload.isMixpack,
      sttPiecePerPack: this.payload.sttPiecePerPack,
      sttNextCommodity: this.payload.sttNextCommodity,
      sttCodAmount: this.payload.codAmount,
      postalCodeDestination: this.payload.postalCodeDestination,
      isPAD: this.payload.isPAD,
      sttWeightAttachFiles: this.fileAdjustmentsFilter
    });
  }

  submitBookCoorporateParam() {
    BookingController.addBookingCorporate({
      payload: new AddBookingCorporate({
        sttClient: this.manualBooking.clientName.id,
        sttNo: this.payload.sttManual,
        sttShipmentId: "",
        sttNoRefExternal: this.payload.referenceExternal,
        sttTaxNumber: this.payload.taxNumber,
        sttGoodsEstimatePrice: this.payload.estimationPrice,
        sttGoodsStatus:
          this.payload.statusGoods || this.statusGoodValueForclients,
        sttOriginCityId: this.payload.originCity,
        sttOriginDistrictId: this.payload.originDistrict,
        sttDestinationCityId: this.payload.destinationCity,
        sttDestinationDistrictId: this.payload.destinationDistrict,
        sttSenderName: this.payload.sender,
        sttSenderPhone: this.payload.phoneSender,
        sttSenderAddress: this.payload.addressSender,
        sttRecipientName: this.payload.receiver,
        sttRecipientAddress: this.payload.addressReceiver,
        sttRecipientAddressType: this.payload.addressReceiverType,
        sttRecipientPhone: this.payload.phoneReceiver,
        sttInsuranceType: this.payload.insurance || this.insuranceAsDefault,
        sttProductType: this.payload.productName,
        sttCommodityCode: this.payload.commodityCode,
        sttClientSttId: 0,
        sttVendorSttId: 0,
        sttIsCod: this.payload.isCODClient,
        sttIsDfod: this.payload.isDFODClient,
        sttIsWoodpacking: false,
        sttPieces: this.payload.sttPieces,
        sttPiecePerPack: Number(this.manualBooking.piecePerPack),
        sttNextCommodity: this.manualBooking.nextCommodity,
        sttCodAmount: this.payload.codAmount,
        postalCodeDestination: this.manualBooking.postalCodeDestination?.id,
        sttWeightAttachFiles: this.fileAdjustmentsFilter
      })}
    );
  }

  get openSuccess() {
    return BookingController.showModalSuccess;
  }

  get messageSuccess() {
    return BookingController.messageSuccess;
  }

  onCloseSuccess() {
    BookingController.removeWeightDimensions();
    this.resetPageCreateBooking();
  }

  renderDetailCalculation = true;
  resetPageCreateBooking() {
    const tempSenders = JSON.parse(
      JSON.stringify({
        customerName: this.payload.sender,
        customerAddress: this.payload.addressSender,
        customerPhoneCode: this.payload.phoneCodeSender,
        customerPhoneflag: this.payload.phoneFlagSender,
        customerPhoneNumber: this.payload.phoneSender
      })
    );
    const tempClient = JSON.parse(
      JSON.stringify(this.manualBooking.clientName)
    );
    this.currentFieldIndex = 0;
    this.resetFormbookingFromController();
    this.resetDestinationAndCommodity();
    BookingController.removeWeightDimensions();
    BookingController.setWeightDimensions({
      grossWeight: !this.$route.query.weight ? '0' : this.$route.query.weight,
      dimension: !this.$route.query.length
        ? {
            length: 10,
            width: 10,
            height: 10
          }
        : {
            length: Number(this.$route.query.length),
            width: Number(this.$route.query.width),
            height: Number(this.$route.query.height)
          },
      volumeWeight: 0.16,
      convertVolumeWeight: "0,16",
      errorHws: false
    });
    this.listKGs = BookingController.weightDimensions.data;
    this.calculationVolumeWeight(0);
    this.renderDetailCalculation = false;
    this.onChooseSenders(tempSenders, false);
    this.manualBooking.clientName = tempClient;
    this.$nextTick(() => {
      this.renderDetailCalculation = true;
    });
    this.discount = {
      isDiscount: false,
      totalTariff: 0,
      totalAfterDiscount: 0,
      totalBeforeDiscount: 0
    };
    this.deliveryFeeType = "non-cod";
    BookingController.setCOD(false);
    BookingController.setDfod(false);
    this.fileAdjustments = [];
    HeavyWeightSurchargeController.setMinimumHeavyWeightSurcharge({
      value: 0,
      existMinimum: false
    });
    this.showWarningHws = false;
  }

  resetFormbookingFromController() {
    BookingController.setShowModalSuccess(false);
    BookingController.setShowModalManualBooking(false);
    BookingController.setDefaultManualBooking();
    BookingController.setError(false);
    BookingController.setEmptyDestination("");
    BookingController.setIncrementManualBooking();
    BookingController.setGuardBookingSubmit(false);
  }

  resetDestinationAndCommodity() {
    const tempProduct = JSON.parse(
      JSON.stringify(this.manualBooking.productName)
    );
    this.handleErrorDestinationCity();
    const productDefault = String(!this.$route.query.product ? tempProduct : this.$route.query.product)
    if (productDefault !== "JUMBOPACK") {
      BookingController.setProductName(productDefault);
    }
    const destinationParam = {
      code: String(this.$route.query.destinationCode),
      name: String(this.$route.query.destinationName),
      cityCode: String(this.$route.query.destinationCityCode),
      status: String(this.$route.query.destinationStatus),
      isCod: String(this.$route.query.destinationCod),
      type: String(this.$route.query.destinationType),
      vendor_code: String(this.$route.query.destinationVendor)
    };
    BookingController.setDestinationCity(
      !this.$route.query.destinationCode ? "" : destinationParam
    );
    const commodityParam = {
      name: String(this.$route.query.commodityName),
      code: String(this.$route.query.commodityCode)
    };
    const defaultCommodity = {
      id: FlagsMedusa.config_commodity_general_others_id.getValue(),
      code: FlagsMedusa.config_commodity_general_others_code.getValue(),
      name: FlagsMedusa.config_commodity_general_others_name.getValue()
    };
    BookingController.setCommodity(
      !this.$route.query.commodityCode ? defaultCommodity : commodityParam
    );
  }

  get isDO() {
    return AccountController.accountData.account_type === "client"
      ? AccountController.accountData.account_type_detail.isDO
      : false;
  }

  get isMixpack() {
    return AccountController.accountData.account_type === "client"
      ? AccountController.accountData.account_type_detail.isMixpack
      : false;
  }

  get reRenderManualBooking() {
    return BookingController.reRenderManualBooking;
  }

  // Validation
  get isMaxAddKgs() {
    return this.listKGs.length < 15;
  }

  isCountGrossWeigth = false;
  validateGrossWeightPerKoli(value: number): boolean {
    if (value > maxKg) {
      this.isCountGrossWeigth = true;
      return true;
    }
    this.isCountGrossWeigth = false;
    return false;
  }

  get validateTotalGrossWeight(): boolean {
    const total = Number(this.totalGrossWeight.replace(",", "."));
    return total > maxKg;
  }

  get validateTotalVolumeWeight(): boolean {
    let total = 0;
    this.listKGs.map((e: any) => (total += Number(e.volumeWeight)));
    return total > maxKg;
  }

  get allowToAddMoreKoliVolume(): boolean {
    return this.validateTotalVolumeWeight;
  }

  get allowToAddMoreKoli(): boolean {
    return this.validateTotalGrossWeight;
  }

  get getAccount(): any {
    return {
      accountType: AccountController.accountData.account_type,
      cityCode: AccountController.accountData.account_type_detail
        .partnerLocation
        ? AccountController.accountData.account_type_detail.partnerLocation
            .city_code
        : "",
      accountClientId: AccountController.accountData.account_type_detail.id
        ? AccountController.accountData.account_type_detail.id
        : "",
      isCodBooking:
        AccountController.accountData.account_type_detail.partnerIsCodBooking,
      accountTypeDetail: AccountController.accountData.account_type_detail
    };
  }

  // clients
  fetchClients(search: string) {
    this.fetchdDataClients(search);
  }

  filterClients = debounce(async (search: string, isDirectory = false) => {
    await this.fetchdDataClients(search, isDirectory);
  }, 250);

  async fetchdDataClients(search: string, isDirectory = false) {
    const replaceNonCod = this.filterClient.shipmentType
      ?.toString()
      .toLowerCase()
      .replace("non cod", "non_cod");
    if (
      this.getAccount.accountType === "partner" ||
      this.getAccount.accountType === "internal"
    ) {
      await PartnerController.getClientNamePOS({
        search,
        page: 1,
        limit: 10,
        isBranch: true,
        isApiCbp: false,
        packageType: replaceNonCod || "",
        cityCode: this.filterClient.cityOriginCode?.code || "",
        isDirectory
      });
    }
  }

  get isErrorGetClient() {
    return PartnerController.errorCauseGetClientsNamePOS;
  }

  goToDetail(item: any) {
    this.manualBooking.clientName = item;
    const isExist =
      this.summaryFilter.filter((key: any) => key.value === item.name).length >
      0;
    if (!isExist) {
      this.summaryFilter.push({
        value: this.manualBooking.clientName?.name || item.name,
        from: "client"
      });
      this.summaryFilter = this.summaryFilter.filter(
        (key: any) =>
          (key.from === "client" && key.value === item.name) ||
          key.from !== "client"
      );
    }
    this.onShowPopupFilterClient();
    this.searchValue = "";
  }

  get clients() {
    if (
      this.getAccount.accountType === "partner" ||
      this.getAccount.accountType === "internal"
    ) {
      return PartnerController.clientDataNamePOS.data.map((e: any) => ({
        id: e.clientId,
        name: e.clientName,
        status: e.clientStatus,
        isDO: e.clientIsDO,
        isCOD: e.clientIsCOD,
        clientCode: e.clientCode,
        clientOriginCity: e.clientOriginCity,
        clientCategory: e.clientCategory,
        clientPackageType: e.clientPackageType?.toString()
      }));
    }

    return [];
  }

  get isLoadingClients() {
    return PartnerController.loadingClientsNamePOS;
  }

  isErrorClient = false;
  isClientDO = false;
  statusCODClient = false;
  isClientCOD = false;
  getClientStatus(obj: any) {
    BookingController.setProductName("");
    if (!obj.status && !obj.isDO) {
      this.pickProductUpselling(this.manualBooking.clientName);
    }

    if (obj.status) {
      this.resetWhenStatusClientTrue();
    } else {
      this.resetWhenStatusClientFalse(obj);
    }

    if (obj) {
      this.codDfodClient(obj.id);
    } else {
      this.deliveryFeeTypeClient = "non-cod";
      this.typeDeliveryClientCOD = false;
      this.typeDeliveryClientDFOD = false;
      this.setValueDeliveryTypeClient();
    }
  }

  typeDeliveryClientCOD = false;
  typeDeliveryClientDFOD = false;
  async codDfodClient(id: number) {
    await detailClientManagement(id);
    this.deliveryFeeTypeClient = "non-cod";
    this.typeDeliveryClientCOD =
      ClientManagementController.detailClientManagement.clientIsCod;
    this.typeDeliveryClientDFOD =
      ClientManagementController.detailClientManagement.clientIsDfod;
    this.setValueDeliveryTypeClient();
  }

  setValueDeliveryTypeClient() {
    const renderDeliveryType: any = this.$refs.deliveryType;
    renderDeliveryType.setValue(this.deliveryFeeTypeClient);
  }

  resetWhenStatusClientTrue() {
    this.isErrorClient = true;
    this.isClientDO = true;
    this.isClientCOD = false;
    this.manualBooking.isCOD = false;
    this.manualBooking.codAmount = 0;
    this.statusCODClient = true;
    this.manualBooking.sttManual = "";
    this.errorCaptionSttManual = "";
    this.error.sttManual = false;
    this.pickProductUpselling(this.manualBooking.clientName);
  }

  resetWhenStatusClientFalse(obj: any) {
    this.statusCODClient = obj.isDO;
    this.isErrorClient = false;

    if (obj.isDO) {
      this.isClientDO = obj.isDO;
      this.manualBooking.sttManual = "";
      this.errorCaptionSttManual = "";
      this.error.sttManual = false;
      this.manualBooking.destinationCity = "";
      this.resetProductUpselling();
    } else {
      if (!this.isProductSelected) {
        this.isClientDO = obj.isDO;
        this.manualBooking.sttManual = "";
        this.errorCaptionSttManual = "";
        this.error.sttManual = false;
      } else {
        this.isClientDO = obj.isDO;
      }
    }

    if (obj.isCOD) {
      this.isClientCOD = true;
    } else {
      this.isClientCOD = false;
      this.manualBooking.isCOD = false;
      this.manualBooking.codAmount = 0;
      this.errorCaptionSttManual = "";
      this.error.sttManual = false;
    }

    this.checkIfCod(
      this.manualBooking.destinationCity?.isCod === "yes",
      this.isClientCOD
    );
  }

  // origin city
  originCityList: Array<any> = [];
  onFocusOriginCity() {
    if (!this.originCityList.length || !this.manualBooking.originCity) {
      LocationController.setLoadingDistrict(true);
      this.fetchOriginCity("");
    }
    this.setFieldIndex(9);
  }
  fetchOriginCity = debounce(async (search: string) => {
    const typeAccuracyAllowed = true;
    const response = await LocationController.getDistrictList({
      search: search,
      status: "",
      page: 1,
      limit: typeAccuracyAllowed ? 50 : 10,
      cache: true
    });
    this.originCityList = this.filterDistrict(response);
  }, 250);

  get isLoadingOriginCity() {
    return LocationController.isLoadingDistrict;
  }

  filterDistrict(response: any) {
    return response
      .filter((key: any) => key.status.toLowerCase() === "active")
      .map((e: any) => ({
        id: e.id,
        name: `${e.name}, ${e.cityName}`,
        code: `${e.code}`,
        cityCode: `${e.cityCode}`,
        status: e.status,
        type: e.type,
        vendor_code: e.vendorCode,
        isCod: e.isCod,
        isFtz: e.isFtz
      }));
  }

  statusGoodForClients = "ecommerce";
  get statusGoodValueForclients() {
    this.manualBooking.statusGoods = this.statusGoodForClients;
    return this.statusGoodForClients;
  }

  set statusGoodValueForclients(value: string) {
    this.statusGoodForClients = value;
  }

  defaultInsuranceTypeForClients = "free";
  get addressTypeValue() {
    return this.manualBooking.addressReceiverType;
  }
  get insuranceAsDefault() {
    this.manualBooking.insurance = this.insuranceForClients;
    return this.insuranceForClients;
  }

  set insuranceForClients(value: string) {
    this.defaultInsuranceTypeForClients = value;
  }
  get insuranceForClients() {
    return this.defaultInsuranceTypeForClients;
  }

  get checkIsDO() {
    return !(this.isClientDO && !this.payload.referenceExternal);
  }

  get checkIsOrigincityFilled() {
    if (this.getAccount.accountType === "partner" && !this.payload.originCity) {
      return false;
    } else
      return !(
        this.getAccount.accountType === "internal" &&
        !this.manualBooking.originCity
      );
  }

  get isFormValidClient() {
    return (
      this.formValidationClientShipmentDetail &&
      this.formValidationClientShipmentGood &&
      this.formValidationClientShipmentOthers &&
      this.formValidationClientShipmentError
    );
  }

  get formValidationClientShipmentDetail() {
    return (
      this.payload.destinationCity &&
      this.payload.destinationDistrict &&
      this.payload.phoneSender &&
      this.payload.phoneReceiver &&
      this.payload.sender &&
      this.payload.receiver &&
      this.payload.addressSender &&
      this.payload.addressReceiver
    );
  }

  get formValidationClientShipmentGood() {
    return (
      this.payload.productName &&
      this.payload.sttGrossWeight &&
      this.payload.sttChargeableWeight &&
      this.payload.commodityCode &&
      this.payload.insurance &&
      this.payload.estimationPrice &&
      this.checkIntersection()
    );
  }

  get formValidationClientShipmentOthers() {
    return (
      this.manualBooking.clientName &&
      !this.isErrorClient &&
      this.checkIsDO &&
      this.checkIsOrigincityFilled &&
      this.payload.addressReceiverType &&
      this.isFtzRequiredForm &&
      this.validationVendorNinja &&
      !this.errorEstimationPrice
    );
  }

  get formValidationClientShipmentError() {
    return (
      !this.error.product &&
      this.errorPhoneSender.success &&
      !this.errorPhoneSender.errorPhoneCode &&
      this.errorPhoneReceiver.success &&
      !this.errorPhoneReceiver.errorPhoneCode &&
      !this.error.insurance &&
      !this.error.sttManual
    );
  }

  getClientID = debounce(() => {
    if (this.getAccount.accountType === "client") {
      ClientManagementController.getDetail(this.getAccount.accountClientId);
    }
  }, 250);

  get detailClientID() {
    return ClientManagementController.detailClientManagement;
  }

  requestData: ApiRequestList = new ApiRequestList();
  listDataConfigurationCOD: ResponsePayloadV2 = new ResponsePayloadV2();
  isEligibleCOD = false;
  isEligibleDFOD = false;
  isInsurance = false;
  listCodActive = [] as any;
  listDfodActive = [] as any;
  listCcoProductType = [] as any;
  listDfodProductType = [] as any;
  dfodMinPrice = 0;
  dfodMaxPrice = 0;

  async fetchListConfigurationCOD() {
    this.listDataConfigurationCOD.loading = true;
    try {
      this.listDataConfigurationCOD = await CodConfigurationController.getList(
        new ApiRequestList({
          page: this.listDataConfigurationCOD.pagination.page,
          limit: this.listDataConfigurationCOD.pagination.limit,
          status: this.requestData.statusData.value
        })
      );
      // cod
      this.listCodActive = this.listDataConfigurationCOD.data.filter(
        (item: any) => item.ccoName === "COD Retail"
      );
      this.codStatusRetail();

      // dfod
      this.listDfodActive = this.listDataConfigurationCOD.data.filter(
        (item: any) => item.ccoName === "DFOD Retail"
      );
      this.dfodStatusRetail();
    } catch (err) {
      this.listDataConfigurationCOD.message = parsingErrorResponse(err).type;
    } finally {
      this.listDataConfigurationCOD.loading = false;
    }
  }

  codStatusRetail() {
    if (this.listCodActive[0].ccoStatus === "active") {
      this.isEligibleCOD = true;
      this.listCcoProductType = this.listCodActive[0].ccoProductType.split(",");
    } else {
      this.isEligibleCOD = false;
    }
  }

  dfodStatusRetail() {
    if (this.listDfodActive[0]?.ccoStatus === "active") {
      this.isEligibleDFOD = true;
      this.listDfodProductType = this.listDfodActive[0].ccoProductType.split(
        ","
      );
      this.dfodMinPrice = this.listDfodActive[0].ccoMinPrice;
      this.dfodMaxPrice = this.listDfodActive[0].ccoMaxPrice;
    } else {
      this.isEligibleDFOD = false;
    }
  }

  codStatusDisabled = false;
  codStatus(formType: any, accountType: string, isReset: boolean) {
    if (isReset) {
      BookingController.setCOD(false);
      this.deliveryFeeType = "non-cod";
    }

    const status = this.statusOfCod;
    return this.codStatusConditonForBooking(status, formType, accountType);
  }

  get statusOfCod() {
    return this.isFormBookManual && this.getAccount.accountType === "partner"
      ? this.statusCodForBookManual
      : this.statusCodForBookClient;
  }

  get statusCodForBookManual() {
    const isCodDestination =
      this.manualBooking.destinationCity?.isCod === "yes";
    return {
      manual: {
        partner:
          this.getAccount.isCodBooking &&
          this.getAccount.accountTypeDetail.type === "pos" &&
          isCodDestination,
        client: isCodDestination
      },
      client: {
        partner: this.isClientCOD && isCodDestination,
        internal: this.isClientCOD && isCodDestination
      }
    };
  }

  get statusCodForBookClient() {
    const isCodDestination =
      this.manualBooking.destinationCity?.isCod === "yes";
    return {
      manual: {
        partner:
          this.getAccount.isCodBooking &&
          this.getAccount.accountTypeDetail.type === "pos" &&
          isCodDestination &&
          this.isEnableCodBooking,
        client: this.detailClientID.clientIsCod && isCodDestination
      },
      client: {
        partner: this.isClientCOD && isCodDestination,
        internal: this.isClientCOD && isCodDestination
      }
    };
  }

  codStatusConditonForBooking(status: any, formType: any, accountType: string) {
    let result = false;
    if (this.isFormBookManual && this.getAccount.accountType === "partner") {
      if (this.isEligibleCOD) {
        if (!this.listCcoProductType.includes(this.manualBooking.productName)) {
          this.manualBooking.isCOD = false;
          this.checkIsInsurance();
        }

        result =
          this.listCcoProductType.includes(this.manualBooking.productName) &&
          status[formType][accountType];
      } else {
        result = false;
      }
    } else {
      result = status?.[formType]?.[accountType];
    }

    if (!result) {
      this.codStatusDisabled = true;
    } else {
      this.codStatusDisabled = false;
    }

    return result;
  }

  dfodStatusDisabled = false;
  dfodStatus() {
    BookingController.setDfod(false);
    this.deliveryFeeType = "non-cod";
    const isCodDestination =
      this.manualBooking.destinationCity?.isCod === "yes";
    let result = false;
    if (this.isEligibleDFOD) {
      if (!this.listDfodProductType.includes(this.manualBooking.productName)) {
        this.manualBooking.isDFOD = false;
        this.checkIsInsurance();
      }

      result = this.listDfodProductType.includes(
        this.manualBooking.productName
      );
    } else {
      result = false;
    }

    if (!result || !isCodDestination) {
      this.dfodStatusDisabled = true;
    } else {
      this.dfodStatusDisabled = false;
    }

    return result;
  }

  checkIsInsurance() {
    if (this.isFormBookManual && this.getAccount.accountType === "partner") {
      const estimasi = Number(
        this.manualBooking.estimationPrice
          .replace(/\./g, "")
          .replaceAll(",", ".")
      );
      this.checkIsFirstRowIsInsurance(estimasi);
    }
    this.processErrorEstimationPrice(this.manualBooking.estimationPrice);
  }

  checkIsFirstRowIsInsurance(estimasi: number) {
    if (this.disableInsuranceCOD || this.disableInsuranceDFOD) {
      this.checkIsCodRetail(estimasi);
    } else {
      this.estimateInsuranceUnderValue(estimasi);
    }
  }

  checkIsCodRetail(estimasi: number) {
    if (this.manualBooking.isCOD || this.manualBooking.isDFOD) {
      this.disabledInsurance = true;
      this.manualBooking.insurance = "free";
      this.insuranceForClients = "free";
      this.handleInsurance(this.manualBooking.estimationPrice);
    } else {
      this.checkEstimateInsurance(estimasi);
    }
  }

  checkEstimateInsurance(estimasi: number) {
    if (estimasi < 5000000) {
      this.checkIsOtopackProduct();
    } else {
      this.setInsuranceBasicPackage();
    }
  }

  checkIsOtopackProduct() {
    if (
      this.manualBooking.productName === "OTOPACK250" ||
      this.manualBooking.productName === "OTOPACK150"
    ) {
      this.setStatusInsurance(
        this.manualBooking.productName,
        this.statusProduct
      );
    }
    this.disabledInsurance = false;
  }

  estimateInsuranceUnderValue(estimasi: number) {
    if (estimasi < 5000000) {
      this.disabledInsurance = false;
    } else {
      this.setInsuranceBasicPackage();
    }
  }

  setInsuranceBasicPackage() {
    this.disabledInsurance = true;
    this.manualBooking.insurance = "basic";
    this.insuranceForClients = "basic";
  }

  errorCaptionSttManual = "";
  get isDisabledSttManual() {
    return isDisableSttManual(this.formType);
  }

  validateSttManual = debounce(async (value: string) => {
    if (value === "") {
      this.errorCaptionSttManual = "";
      this.error.sttManual = false;
      return;
    }
    const result = await BookingController.validateSttManual({
      clientId: this.getValidateSttManual,
      sttManual: value,
      bookingType:
        router.currentRoute.value.meta.formType === "manual" &&
        this.getAccount.accountType === "partner"
          ? "pos"
          : "client",
      productType: this.manualBooking.productName
    });
    this.error.sttManual = !result.isAllowBooking;
    this.errorCaptionSttManual = result.errorMessage;
  }, 500);

  get getValidateSttManual() {
    if (router.currentRoute.value.meta.formType === "client") {
      return this.manualBooking.clientName.id;
    } else if (this.getAccount.accountType === "client") {
      return this.getAccount.accountClientId;
    } else {
      return "";
    }
  }

  // error embargo
  get isErrorEmbargo() {
    return BookingController.isErrorEmbargo;
  }

  errorEmbargo = {
    originCity: false,
    destinationCity: false,
    productType: false,
    comodity: false,
    weightStatus: false,
    lengthStatus: false
  };

  onUpdateEmbargoStatusFail() {
    if (this.isErrorEmbargo) {
      this.updateStatusErrorEmbargo(false);
    }
  }

  setProductUpsellingcityOrigin() {
    this.pickProductUpselling(this.manualBooking.originCity);
  }

  get isShipperAndConsigneeAddressIdentical() {
    if (
      this.manualBooking.addressSender === "" ||
      this.manualBooking.addressReceiver === ""
    ) {
      return false;
    }
    return (
      this.manualBooking.addressSender.toLowerCase() ===
      this.manualBooking.addressReceiver.toLowerCase()
    );
  }

  get isFtzRequiredForm() {
    if (this.isFtzAccount && this.accountIsNonForeign) {
      return !!(
        (this.manualBooking.statusGoods || this.statusGoodValueForclients) &&
        this.manualBooking.piecePerPack &&
        this.manualBooking.nextCommodity
      );
    }

    if (this.isFtzAccount && !this.accountIsNonForeign) {
      return !!this.manualBooking.taxNumber;
    }

    return true;
  }

  get isFtzAccount() {
    return this.getAccount.accountType === "internal"
      ? this.manualBooking.originCity?.isFtz === "yes"
      : AccountController.accountData.account_location?.city_free_trade_zone ===
          "yes";
  }

  isEstimationTyped = false;
  fullFilledCOD(event: any) {
    this.isEstimationTyped = true;

    if (this.codStatus(this.formType, this.getAccount.accountType, false)) {
      this.manualBooking.codAmount = event;
    }

    if (this.deliveryFeeTypeClient === "cod") {
      this.manualBooking.codAmount = event;
    }

    if (!this.disableAsuranceOtoPack) {
      this.handleInsurance(event);
    }

    this.processErrorEstimationPrice(event);
  }

  errorEstimationPrice = false;
  errMsgPrice = "harga barang harus di atas atau sama dengan  5 juta";
  processErrorEstimationPrice(price: string) {
    const isNonCodValidate = this.deliveryFeeType === "non-cod";
    const isDfodValidate = this.deliveryFeeType === "dfod";
    const isCodValidate = this.deliveryFeeType === "cod";

    const estimation = Number(price.replace(/\./g, "").replaceAll(",", "."));
    if (
      this.isInvalidCodAndDfod(isCodValidate, isDfodValidate) ||
      isNonCodValidate
    ) {
      this.validateOttopack(estimation);
    } else {
      this.validateMainEstimationPrice(
        estimation,
        isCodValidate,
        isDfodValidate
      );
    }

    this.checkProfitInsurance(estimation, isNonCodValidate, isDfodValidate);
  }

  typeDeliveryForInsuranceProfit(
    isNonCodValidate: boolean,
    isDfodValidate: boolean
  ) {
    return (
      (isNonCodValidate || isDfodValidate) &&
      this.isInsuranceBasic &&
      this.isFormBookManual
    );
  }

  checkProfitInsurance(
    val: number,
    isNonCodValidate: boolean,
    isDfodValidate: boolean
  ) {
    if (this.typeDeliveryForInsuranceProfit(isNonCodValidate, isDfodValidate)) {
      if (this.disableInsuranceDFOD) {
        this.insuranceCondition = 3;
        this.errorEstimationPrice = false;
      } else {
        this.insuranceLogic(val);
      }
    }
  }

  insuranceLogic(val: any) {
    if (this.accountIsNonForeign) {
      this.insuranceLogicIndonesian(val);
    } else {
      this.insuranceLogicMy(val);
    }
  }

  insuranceLogicMy(val: any) {
    if (val >= 3.2 && val < 1492) {
      this.insuranceCondition = 2;
      this.errorEstimationPrice = false;
    } else if (val >= 1492) {
      this.insuranceCondition = 6;
      this.errorEstimationPrice = false;
    } else {
      this.insuranceCondition = 4;
      if (this.isEstimationTyped) {
        this.errorEstimationPrice = true;
        this.errMsgPrice = this.$t("bookingShipment.validate.itemPrice");
      }
    }
  }

  insuranceLogicIndonesian(val: any) {
    if (val >= 10000 && val < 5000000) {
      this.insuranceCondition = 2;
      this.errorEstimationPrice = false;
    } else if (val >= 5000000) {
      this.insuranceCondition = 6;
      this.errorEstimationPrice = false;
    } else {
      this.insuranceCondition = 4;
      if (this.isEstimationTyped) {
        this.errorEstimationPrice = true;
        this.errMsgPrice = "Harga barang minimal Rp10.000";
      }
    }
  }

  resetProfitInsurance() {
    this.insuranceCondition = 3;
    if (!this.bookingPosForPos) this.errorEstimationPrice = false;
  }

  validateOttopack(estimation: number) {
    if (this.disableAsuranceOtoPack && this.estimationForOttopack(estimation)) {
      this.errorEstimationPrice = true;
      this.errMsgPrice = "harga barang harus di atas atau sama dengan  5 juta";
    } else {
      this.errorEstimationPrice = false;
    }
  }

  isInvalidCodAndDfod(isCodValidate: boolean, isDfodValidate: boolean) {
    return (
      (!this.manualBooking.isCOD && isCodValidate) ||
      (!this.manualBooking.isDFOD && isDfodValidate)
    );
  }

  estimationForOttopack(estimasi: number) {
    return estimasi > 0 && estimasi < 5000000;
  }

  validateMainEstimationPrice(
    estimation: number,
    isCodValidate: boolean,
    isDfodValidate: boolean
  ) {
    this.minPriceZero();
    this.maxPriceZero();

    const label = isCodValidate ? "COD" : "DFOD";
    const subLabelMinPrice = isCodValidate
      ? this.listCodActive[0]?.ccoMinPrice
      : this.listDfodActive[0]?.ccoMinPrice;
    const subLabelMaxPrice = isCodValidate
      ? this.listCodActive[0]?.ccoMaxPrice
      : this.listDfodActive[0]?.ccoMaxPrice;
    const validate = {
      codValidate: isCodValidate,
      dfodValidate: isDfodValidate
    };
    const subLabel = {
      minPrice: subLabelMinPrice,
      maxPrice: subLabelMaxPrice
    };

    this.conditionalValidationEstimationPrice(
      estimation,
      validate,
      subLabel,
      label
    );
  }

  minPriceZero() {
    if (
      this.listCodActive[0]?.ccoMinPrice === 0 ||
      this.listDfodActive[0]?.ccoMinPrice === 0
    ) {
      return;
    }
  }

  maxPriceZero() {
    if (
      this.listCodActive[0]?.ccoMaxPrice === 0 ||
      this.listDfodActive[0]?.ccoMaxPrice === 0
    ) {
      return;
    }
  }

  conditionalValidationEstimationPrice(
    estimation: number,
    validate: any,
    subLabel: any,
    label: string
  ) {
    this.validateEstimationPriceIsTrue(estimation, validate, subLabel, label);
    this.validateEstimationPriceIsFalse(estimation, validate);
  }

  validateEstimationPriceIsFalse(estimation: number, validate: any) {
    if (
      this.estimationCompareMinAndMaxPrice(
        estimation,
        this.listCodActive[0]?.ccoMinPrice,
        this.listCodActive[0]?.ccoMaxPrice,
        validate.codValidate
      ) ||
      this.estimationCompareMinAndMaxPrice(
        estimation,
        this.listDfodActive[0]?.ccoMinPrice,
        this.listDfodActive[0]?.ccoMaxPrice,
        validate.dfodValidate
      )
    ) {
      this.errorEstimationPrice = false;
    }
  }

  validateEstimationPriceIsTrue(
    estimation: number,
    validate: any,
    subLabel: any,
    label: string
  ) {
    this.validateEstimationPriceLess(estimation, validate, subLabel, label);
    this.validateEstimationPriceMore(estimation, validate, subLabel, label);
  }

  validateEstimationPriceLess(
    estimation: number,
    validate: any,
    subLabel: any,
    label: string
  ) {
    if (
      this.estimationLessThanPrice(
        estimation,
        this.listCodActive[0]?.ccoMinPrice,
        validate.codValidate
      ) ||
      this.estimationLessThanPrice(
        estimation,
        this.listDfodActive[0]?.ccoMinPrice,
        validate.dfodValidate
      )
    ) {
      this.errMsgPrice = `Minimal Harga Barang ${label} ${formatPriceWithoutCurrency(
        subLabel.minPrice
      )}`;
      this.errorEstimationPrice = true;
    }
  }

  validateEstimationPriceMore(
    estimation: number,
    validate: any,
    subLabel: any,
    label: string
  ) {
    if (
      this.estimationMoreThanPrice(
        estimation,
        this.listCodActive[0]?.ccoMaxPrice,
        validate.codValidate
      ) ||
      this.estimationMoreThanPrice(
        estimation,
        this.listDfodActive[0]?.ccoMaxPrice,
        validate.dfodValidate
      )
    ) {
      this.errMsgPrice = `Maximal Harga Barang ${label} ${formatPriceWithoutCurrency(
        subLabel.maxPrice
      )}`;
      this.errorEstimationPrice = true;
    }
  }

  estimationLessThanPrice(estimasi: number, price: number, validate: boolean) {
    return estimasi < price && validate;
  }

  estimationMoreThanPrice(estimasi: number, price: number, validate: boolean) {
    return estimasi > price && validate;
  }

  estimationCompareMinAndMaxPrice(
    estimasi: number,
    minPrice: number,
    maxPrice: number,
    validate: boolean
  ) {
    return estimasi >= minPrice && estimasi <= maxPrice && validate;
  }

  checkIfCod(
    destination: boolean,
    client: boolean = this.detailClientID.clientIsCod
  ) {
    if (!client || !destination) {
      BookingController.setCOD(false);
      BookingController.setDfod(false);
      BookingController.setCODAmount(0);
    }
  }

  replaceNewLine(value: string) {
    return removeNewLineWhitespace(value, " ");
  }

  // sender
  get errorPhoneSender() {
    return {
      success: this.error.successPhoneSender,
      errorPhone: this.error.phoneSender,
      errorMandatory: this.errorMandatory[5],
      errorPhoneCode: this.error.phoneCodeSender,
      message: this.error.errorMessagePhoneSender
    };
  }
  setErrorPhoneCodeSender(status: boolean) {
    this.error.phoneCodeSender = status;
  }
  setPhoneSender(val: string) {
    BookingController.setPhoneSender(val);
  }

  setPhoneCodeSender(val: string) {
    BookingController.setPhoneCodeSender(val);
  }

  setPhoneFlagSender(val: string) {
    BookingController.setPhoneFlagSender(val);
  }

  // receiver
  get errorPhoneReceiver() {
    return {
      success: this.error.successPhoneReceiver,
      errorPhone: this.error.phoneReceiver,
      errorMandatory: this.errorMandatory[6],
      errorPhoneCode: this.error.phoneCodeReceiver,
      message: this.error.errorMessagePhoneReceiver
    };
  }

  setErrorPhoneCodeReceiver(status: boolean) {
    this.error.phoneCodeReceiver = status;
  }

  setPhoneReceiver(val: string) {
    BookingController.setPhoneReceiver(val);
  }

  setPhoneCodeReceiver(val: string) {
    BookingController.setPhoneCodeReceiver(val);
  }

  setPhoneFlagReceiver(val: string) {
    BookingController.setPhoneFlagReceiver(val);
  }

  // postal code
  postalCodeDestinationOptions: any = [];
  setPostalCodeDestination(value: string) {
    if (value) {
      BookingController.setPostalCodeDestination({
        id: value,
        name: value
      });
      return;
    }
    BookingController.setPostalCodeDestination("");
  }

  // configurable rule product
  productConfigurationRuleList: ProductConfigurationRuleList = new ProductConfigurationRuleList();
  async fetchProductConfigurableRule() {
    try {
      this.productConfigurationRuleList = await ProductController.fetchProductConfigurationRule(
        new ProductConfigurationRuleRequestParams()
      );
    } catch (err) {
      this.productConfigurationRuleList = new ProductConfigurationRuleList();
    }
  }

  estimateSla = {
    slaMin: 0,
    slaMax: 0,
    transport: ""
  };
  get isShowWarningSla() {
    return this.estimateSla.slaMin >= 7;
  }
  fetchSlaEstimation() {
    BookingController.checkTransportAndEstimateSlaBooking({
      originCity:
        this.origin.cityCode || this.manualBooking.originCity.cityCode,
      destinationCity: this.manualBooking.destinationCity.cityCode,
      originDistrict: this.origin.code || this.manualBooking.originCity.code,
      destinationDistrict: this.manualBooking.destinationCity.code,
      product: this.manualBooking.productName
    }).then(response => {
      this.estimateSla = response;
    });
  }

  formatPrice(value: number) {
    return !AccountController.accountData.accountIsForeign
      ? formatPriceRP(value)
      : formatPriceRM(value);
  }

  discount: Diskon = {
    isDiscount: false,
    totalTariff: 0,
    totalAfterDiscount: 0,
    totalBeforeDiscount: 0
  };

  setDiscount(params: Diskon) {
    this.discount = {
      isDiscount: params.isDiscount,
      totalTariff: params.totalTariff,
      totalAfterDiscount: params.totalAfterDiscount,
      totalBeforeDiscount: params.totalBeforeDiscount
    };
  }

  disableAsuranceOtoPack = false;
  statusProduct = false;
  setStatusInsurance(productName: string, productStatus: boolean) {
    this.statusProduct = productStatus;
    const product =
      (productName === "OTOPACK150" || productName === "OTOPACK250") &&
      productStatus;
    if (product) {
      this.insuranceForClients = "basic";
      this.manualBooking.insurance = "basic";
      this.disableAsuranceOtoPack = true;
      this.processErrorEstimationPrice(this.manualBooking.estimationPrice);
    } else {
      this.handleInsurance(this.manualBooking.estimationPrice);
      this.disableAsuranceOtoPack = false;
      if (this.insuranceAsDefault === "free" && !this.bookingPosForPos)
        this.errorEstimationPrice = false;
    }
  }

  phoneMustMSISDN() {
    return this.isEligibleCOD && this.manualBooking.isCOD
      ? ValidateMsisdn(
          this.payload.phoneSender,
          "Masukkan nomor telepon dengan tipe MSISDN"
        )
      : ValidateInputPhoneNumber(this.payload.phoneSender);
  }

  get productsDeliveryWithoutOnePack() {
    return this.productsDelivery.filter(
      (e: ProductDeliveryData) => e.name !== "ONEPACK"
    );
  }

  get isCodRetailApplied() {
    return (
      (this.isEligibleCOD && this.manualBooking.isCOD) ||
      (this.isEligibleDFOD && this.manualBooking.isDFOD)
    );
  }

  get errorProduct() {
    if (!this.errorDestintaionProductUpselling) {
      if (this.errorMandatory[1]) {
        return this.$t("bookingShipment.error.serviceType");
      }

      if (this.errorEmbargo.productType) {
        return this.$t("bookingShipment.error.embargoStatus");
      }
    }

    return "";
  }

  errorDestintaionProductUpselling = false;
  setErrorDestintaionProductUpselling(status: boolean) {
    this.errorDestintaionProductUpselling = status;
    BookingController.setErrorDestination(status);
  }

  get clientlientIdForProductUpselling() {
    let accountRefId = 0;
    let accountType = "";

    if (this.isFormBookManual && this.getAccount.accountType === "partner") {
      accountRefId = 0;
      accountType = "pos";
    } else if (
      this.isFormBookClient &&
      this.getAccount.accountType === "partner"
    ) {
      accountRefId = this.manualBooking.clientName.id;
      accountType = "client";
    } else if (
      this.isFormBookManual &&
      this.getAccount.accountType === "client"
    ) {
      accountRefId = this.getAccount.accountClientId;
      accountType = "client";
    } else {
      accountRefId = this.manualBooking.clientName.id;
      accountType = "client";
    }

    return {
      accountRefId,
      accountType
    };
  }

  get isOnePack() {
    return (
      (this.isFormBookManual && this.getAccount.accountType === "client") ||
      this.isFormBookClient
    );
  }

  additionalInfo: any = {
    commodity: "",
    files: []
  };

  updateCommodity(val: string) {
    this.additionalInfo.commodity = val;
  }

  updateFiles(files: string[]) {
    this.additionalInfo.files = files;
  }

  get isProductInterpack() {
    return this.manualBooking.productName === "INTERPACK";
  }

  get disableDetailCommodity() {
    if (this.isProductInterpack) {
      return !this.additionalInfo.commodity;
    }

    return false;
  }

  get disableProofCommodity() {
    if (this.isProductInterpack) {
      const newFiles = this.additionalInfo.files.filter((item: string) => item);
      return newFiles.length < 3;
    }

    return false;
  }

  tabKey = 0;
  resetPhoto() {
    this.additionalInfo = {
      commodity: "",
      files: []
    };
    this.tabKey++;
  }

  get eligibleAdditionalInfo() {
    return (
      FlagsMedusa.flag_release_interpack_additional_info.isEnabled() &&
      this.isFormBookManual &&
      this.getAccount.accountType === "partner"
    );
  }

  deliveryFeeType = "non-cod";
  get deliveryFeeTypes() {
    return [
      { name: "Non COD", value: "non-cod", disabled: false, logo: "" },
      {
        name: "Cash On Delivery",
        value: "cod",
        disabled: this.codStatusDisabled,
        logo: "cod-logo"
      },
      {
        name: "Delivery Fee on Delivery",
        value: "dfod",
        disabled: this.dfodStatusDisabled,
        logo: "dfod-logo"
      }
    ];
  }

  onChangeDeliveryFeeType(value: string) {
    this.deliveryFeeType = value;
    this.changeCod(value);
    this.changeDfod(value);
    this.checkIsInsurance();
    this.handleInsurance(this.manualBooking.estimationPrice);
  }

  changeCod(value: string) {
    if (value === "cod") {
      this.insuranceCondition = 5;
      BookingController.setCOD(true);
    } else {
      this.flipInsurancesProfit();
      BookingController.setCOD(false);
      this.errorEstimationPrice = false;
    }
  }

  flipInsurancesProfit() {
    if (this.insuranceAsDefault === "free") {
      this.resetProfitInsurance();
    } else {
      this.changeToInsuranceProfit();
    }
  }

  changeDfod(value: string) {
    if (value === "dfod") {
      BookingController.setDfod(true);
    } else {
      BookingController.setDfod(false);
    }
  }

  get checkPhoneValidation() {
    return (
      this.payload.isCOD && (this.isFormBookClient || this.isFormBookManual)
    );
  }

  get codValidation() {
    return (
      (this.payload.isCOD || this.payload.isPAD) &&
      (this.isFormBookClient || this.isFormBookManual)
    );
  }

  insuranceCondition = 1;
  get isInsuranceBasic() {
    return (
      this.insuranceAsDefault === "basic" ||
      (this.insuranceAsDefault === "free" && this.bookingPosForPos)
    );
  }

  get insuranceDetail() {
    return BookingController.insuranceTypeDelivery.data.find(
      (item: InsuranceTypeDeliveryData) => item.value === "basic"
    );
  }

  get insuranceProfit() {
    const premi =
      (Number(this.insuranceDetail?.cost) / 100) *
      Number(
        this.manualBooking.estimationPrice
          .replace(/[.]/g, "")
          .replaceAll(",", ".")
      );
    const premiRound = this.accountIsNonForeign
      ? premi
      : (Math.ceil(premi * 10) / 10).toLocaleString(undefined, {
          maximumFractionDigits: 1
        });

    const profit =
      (Number(this.insuranceDetail?.commissionPos) / 100) * Number(premiRound);
    return profit;
  }

  get accountIsNonForeign() {
    return !AccountController.accountData.accountIsForeign;
  }

  get allowShowZipCode() {
    return this.accountIsNonForeign
      ? this.manualBooking.productName === "INTERPACK"
      : true;
  }

  get cityForWoodPacking() {
    return this.origin.cityCode || this.manualBooking.originCity.cityCode;
  }

  getWoodPacking() {
    this.manualBooking.isWoodpack = false;
    const renderWoodPacking: any = this.$refs.woodpacking;
    renderWoodPacking.resetData();
    renderWoodPacking.getConfig();
  }

  fileAdjustments: any[] = new Array(5).fill("");
  async uploadAdjustmentFileFromComponent(params: UploadMultiple) {
    this.setLoadingProcessUploadFileAdjustment(true);
    const file = await uploadAdjustmentFile(params);
    this.fileAdjustments[params.index] = file;
    this.setLoadingProcessUploadFileAdjustment(false);
  }

  async deleteAdjustmentFileFromComponent(index: number) {
    this.setLoadingProcessUploadFileAdjustment(true);
    const fileBeDeleted = this.fileAdjustments[index];
    const del = await deleteAdjustmentFile(fileBeDeleted);

    if (del.data[fileBeDeleted]) {
      this.fileAdjustments[index] = "";
    }

    this.setLoadingProcessUploadFileAdjustment(false);
  }

  setLoadingProcessUploadFileAdjustment(status: boolean) {
    MainAppController.setLoadingModal(status);
  }

  get isAllowUploadAdjustmentFile() {
    return (
      this.formType === "client" ||
      (this.formType === "manual" && this.getAccount.accountType === "client")
    );
  }

  get fileAdjustmentsFilter() {
    return this.fileAdjustments?.filter((item: string) => item);
  }

  deliveryFeeTypeClient = "non-cod";
  get codDfodClientPermission() {
    return (
      (this.formType === "manual" &&
        this.getAccount.accountType === "client") ||
      (this.formType === "client" &&
        this.getAccount.accountType === "partner") ||
      (this.formType === "client" && this.getAccount.accountType === "internal")
    );
  }

  setCodDfodClient() {
    if (
      this.formType === "manual" &&
      this.getAccount.accountType === "client"
    ) {
      this.deliveryFeeTypeClient = "non-cod";
      this.typeDeliveryClientCOD = this.getAccount.accountTypeDetail.isCod;
      this.typeDeliveryClientDFOD = this.getAccount.accountTypeDetail.isDfod;
    }
  }

  get isClientForClient() {
    return (
      this.formType === "manual" && this.getAccount.accountType === "client"
    );
  }

  get codValueForEndpointBookingManual() {
    let codValue = this.manualBooking.isDFOD ? true : this.manualBooking.isCOD;

    if (this.isClientForClient && this.deliveryFeeTypeClient === "cod") {
      codValue = true;
    }

    if (this.isClientForClient && this.deliveryFeeTypeClient === "dfod") {
      codValue = true;
    }

    return codValue;
  }

  get dfodValueForEndpointBookingManual() {
    let dfodValue = this.manualBooking.isDFOD;

    if (this.isClientForClient && this.deliveryFeeTypeClient === "dfod") {
      dfodValue = true;
    }

    return dfodValue;
  }

  get disableHargaBarangCod() {
    return this.deliveryFeeTypeClient === "cod" ? false : this.codStatusDisabled
  }

  get placeholderItemPrice() {
    let placeholder = this.$t("bookingShipment.placeholder.itemPrice");

    if (this.bookingClientForm && this.insuranceAsDefault === "free") {
      placeholder = "e.g 20.000";
    }

    return placeholder;
  }

  get bookingClientForm() {
    return (
      this.bookingPosForClient ||
      this.bookingClientForClient ||
      this.bookingInternalForClient
    );
  }

  get bookingPosForPos() {
    return this.isFormBookManual && this.getAccount.accountType === "partner";
  }

  get bookingPosForClient() {
    return this.isFormBookClient && this.getAccount.accountType === "partner";
  }

  get bookingClientForClient() {
    return this.isFormBookManual && this.getAccount.accountType === "client";
  }

  get bookingInternalForClient() {
    return this.isFormBookClient && this.getAccount.accountType === "internal";
  }

  get eligibleToWoodpacking() {
    return (
      (this.formType === "manual" &&
        this.getAccount.accountType === "client") ||
      (this.formType === "client" &&
        this.getAccount.accountType === "partner") ||
      (this.formType === "client" && this.getAccount.accountType === "internal")
    );
  }


  get configHeavyWeightMinimum() {
    return HeavyWeightSurchargeController.heavyWeightMinimum;
  }

  async getConfigHws(product: any, commodity: any) {
    await HeavyWeightSurchargeController.getDetailMinimumHeavyWeightSurcharge({
      id: 1,
      product,
      commodity
    });
    if (!this.configHeavyWeightMinimum.minimumExist) {
      await HeavyWeightSurchargeController.getDetailMinimumHeavyWeightSurcharge(
        {
          id: 2,
          product,
          commodity
        }
      );
    }
  }

  showWarningHws = false;

  get isErrorHws() {
    return this.listKGs.map((key: any) => key.errorHws).includes(true);
  }

  async onChangeCommodities() {
    this.onUpdateEmbargoStatusFail();
    if (
      this.manualBooking.productName &&
      this.manualBooking.commodityCode.code
    ) {
      await this.getConfigHws(
        this.manualBooking.productName,
        this.manualBooking.commodityCode.code
      );
      this.onSetErrorHws();
    }
  }

  onSetErrorHws() {
    this.showWarningHws =
      this.configHeavyWeightMinimum.minimumExist &&
      this.listKGs.length < 2 &&
      this.listKGs[0]?.grossWeight?.replace(",", ".") >=
        this.configHeavyWeightMinimum.heavyWeightMinimum;
    this.listKGs.forEach((key: any, index: number) => {
      this.listKGs[index].errorHws =
        this.configHeavyWeightMinimum.minimumExist &&
        +key.grossWeight?.replace(",", ".") >=
          this.configHeavyWeightMinimum.heavyWeightMinimum &&
        this.listKGs.length > 1;
    });
  }

  setOnValidationHws(value = "", index = 0) {
    this.showWarningHws =
      this.configHeavyWeightMinimum.minimumExist &&
      this.listKGs.length < 2 &&
      +value?.replace(",", ".") >=
        this.configHeavyWeightMinimum.heavyWeightMinimum;
    this.listKGs[index].errorHws =
      this.configHeavyWeightMinimum.minimumExist &&
      this.listKGs.length > 1 &&
      +value?.replace(",", ".") >=
        this.configHeavyWeightMinimum.heavyWeightMinimum;
  }

  filterClient = {
    cityOriginCode: "" as any,
    shipmentType: []
  };

  get sumFilter(): number {
    return (
      (this.filterClient.cityOriginCode?.name ? 1 : 0) +
      (this.filterClient.shipmentType.length ? 1 : 0)
    );
  }
  isAdvancedFilterOpened = false;
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }
  onChangeAdvancedFilter() {
    if (this.showPopupFilterClient) {
      this.fetchdDataClients(this.manualBooking.clientName?.name || "", true);
    }
  }

  // city destination
  fetchCityDestination = debounce((search: string) => {
    search = search.split(" - ").length > 1 ? search.split(" - ")[1] : search;
    if (!search || search.length > 2) {
      LocationController.getCityList({
        page: 1,
        limit: 10,
        search,
        status: ""
      });
    }
  });
  get loadingCityDestination() {
    return LocationController.isLoading;
  }
  get cityDestinationData() {
    const listData = LocationController.cityData.cityData
      .filter(item => item.name !== "")
      .map(item => {
        return { ...item, name: `${item.code} - ${item.name}` };
      });

    return [{ id: 0, name: "All", code: "" }, ...listData];
  }
  onChangeCityDestination(item: any) {
    this.filterClient.cityOriginCode = this.filterClient.cityOriginCode || "";
    this.onChangeAdvancedFilter();
    if (this.filterClient.cityOriginCode || item) {
      this.summaryFilter.push({
        value: `${
          item?.name === "All"
            ? "All City"
            : this.filterClient.cityOriginCode?.name
        }`,
        from: "city"
      });
      const isExist =
        this.summaryFilter.filter((key: any) => key.from === "city").length > 1;
      if (isExist) {
        const filterAgain = this.summaryFilter.filter(
          (key: any) =>
            (key.from === "city" && key.value === item.name) ||
            key.from !== "city"
        );
        this.summaryFilter = filterAgain;
      }
    } else this.onDeleteSummary("city");
  }

  onChangeShipmentType(item: any) {
    const filterNotSame = this.summaryFilter.filter(
      (key: any) => key.from !== "shipment"
    );
    this.onChangeAdvancedFilter();
    if (!item.length) this.onDeleteSummary("shipment");
    else
      this.summaryFilter[filterNotSame.length > 0 ? 1 : 0] = {
        value: item.join(" & "),
        from: "shipment"
      };
  }

  onResetFilter() {
    this.filterClient.cityOriginCode = "";
    this.filterClient.shipmentType = [];
    this.summaryFilter = [];
    this.fetchdDataClients("");
  }

  onDeleteSummary(from: string) {
    this.summaryFilter = this.summaryFilter.filter(
      (key: any) => key.from !== from
    );
    switch (from) {
      case "city":
        this.filterClient.cityOriginCode = "";
        break;
      case "shipment":
        this.filterClient.shipmentType = [];
        break;
      default:
        break;
    }
  }

  summaryFilter: any[] = [];

  get optionsProduct() {
    return ["COD", "DFOD", "DO", "Non COD"];
  }

  showPopupFilterClient = false;

  async onShowPopupFilterClient(isDirectory = false) {
    await this.filterClients(
      this.manualBooking.clientName?.name || "",
      isDirectory
    );
    this.showPopupFilterClient = !this.showPopupFilterClient;
  }

  onClosePopupFilter() {
    this.onShowPopupFilterClient(false);
    this.searchValue = "";
  }

  // filter search
  searchValue = "";

  onSearchValue(val: string) {
    this.searchValue = val;
    this.filterClients(this.searchValue, true);
  }

  clearSearch() {
    this.searchValue = "";
    this.filterClients("", true);
  }

  get columns() {
    return [
      {
        name: "Nama Klien",
        styleHead: "w-56 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.name}</div>`;
        }
      },
      {
        name: "Kota Asal",
        styleHead: "w-48 text-left",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.clientOriginCity}</div>`;
        }
      },
      {
        name: "Tipe Klien",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.clientCategory}</div>`;
        }
      },
      {
        name: "Layanan Pengiriman",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.clientPackageType}</div>`;
        }
      },
      {
        name: "Kode Klien",
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.clientCode}</div>`;
        }
      }
    ];
  }

  pagination = new PaginationV2();

  get isEmpty(): boolean {
    return !this.clients.length && this.sumFilter === 0;
    // add no filter
  }
}
