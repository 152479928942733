
import { Options, Vue, prop } from "vue-class-component";
import { PropType } from "vue";

class Props {
  dataSearch = prop<any[]>({
    default: [],
    type: Array
  });
  onSearch = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  phone = prop<string>({
    default: "",
    type: String
  });
  code = prop<string>({
    default: "",
    type: String
  });
  flag = prop<string>({
    default: "",
    type: String
  });
  error = prop<boolean>({
    default: false,
    type: Boolean
  });
  errorCaption = prop<string>({
    default: "Error",
    type: String
  });
  maxLength = prop<number>({
    default: 14,
    type: Number
  });
  format = prop<any>({
    default: null,
    type: Function
  });
  placeholder = prop<string>({
    default: "",
    type: String
  });
  disabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  prefixWidth = prop<string>({
    default: "w-2/12",
    type: String
  });
}

@Options({
  emits: ["change", "focus", "setValue", "pasteText"]
})
export default class SelectGroup extends Vue.with(Props) {
  isOpenSearch = false;
  onOpenSearch() {
    this.isOpenSearch = true;
  }
  onCloseSearch() {
    this.isOpenSearch = false;
  }
  onChooseSearch(item: any) {
    this.$emit("setValue", item);
  }
  formatText(e: any) {
    e.target.value = this?.format(e.target.value);
    this.$emit("change", e.target.value);
  }
  onFocus(e: any) {
    this.$emit("focus", e);
  }
  pasteText() {
    this.$emit("pasteText");
  }
}
