import { QueryParamsEntities } from "./MainApp";
import { PaginationV2 } from "./Pagination";
import formatDateNormal from "@/app/infrastructures/misc/common-library/FormatDateNormal";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";

export class PickupSchedule {
  pickupScheduleId = 0;
  pickupScheduleName = "";
  pickupScheduleClientParentId = 0;
  pickupScheduleClientId = 0;
  pickupScheduleClientName = "";
  pickupScheduleDistrictName = "";
  pickupScheduleAddress = "";
  pickupScheduleGeoloc = "";
  pickupScheduleCourierNote = "";
  pickupScheduleStart = "";
  pickupScheduleEnd = "";
  pickupScheduleTime = "";
  pickupScheduleCityTimezone = "";
  pickupScheduleIntervalWeek = 0;
  pickupScheduleRepeatDays = "";
  pickupScheduleTransportType = "";
  pickupScheduleServiceCodes = "";
  pickupScheduleCreatedAt = "";
  pickupScheduleCreatedBy = "";
  pickupScheduleUpdatedAt = "";
  pickupScheduleUpdatedBy = "";
  pickupScheduleStatus = "";
  pickupScheduleEstTotalKoli = 0;
  pickupScheduleEstTotalTonase = 0;
  pickupScheduleLastRun = "";
  pickupScheduleNextRun = "";
  pickupScheduleCityId = "";
  pickupScheduleDistrict = "";
  constructor(fields?: Partial<PickupSchedule>) {
    Object.assign(this, fields);
  }

  get formatCreatedAt() {
    return formatDate(this.pickupScheduleCreatedAt);
  }
  get formatUpdatedAt() {
    return formatDate(this.pickupScheduleUpdatedAt);
  }

  get formatStartAt() {
    return formatDateNormal(this.pickupScheduleStart, "DD MMMM YYYY");
  }
  get formatEndAt() {
    return formatDateNormal(this.pickupScheduleEnd, "DD MMMM YYYY");
  }

  get formatNextRun() {
    return formatDateNormal(this.pickupScheduleNextRun, "dddd DD MMMM YYYY");
  }
}

export class PickupScheduleList {
  pagination = new PaginationV2();
  data: PickupSchedule[] = [new PickupSchedule()];
  constructor(fields?: Partial<PickupScheduleList>) {
    Object.assign(this, fields);
  }
}

export class RequestPickupScheduleList {
  psClientId = 0;
  psTransportType = "";
  psServiceCodes = "";
  psStatus = "";
  page = 1;
  limit = 20;
  isTotalData = false;
  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
  constructor(fields?: Partial<RequestPickupScheduleList>) {
    Object.assign(this, fields);
  }
}

export class CourierVehicle {
  id = 0;
  vehicleName = "";
  constructor(fields?: Partial<CourierVehicle>) {
    Object.assign(this, fields);
  }
}

export class UpdateStatusPickupSchedule {
  psStatus = "";
  constructor(fields?: Partial<UpdateStatusPickupSchedule>) {
    Object.assign(this, fields);
  }
}
