import { PickupScheduleApiRepository } from "@/app/infrastructures/repositories/api/PickupScheduleApiRepository";
import {
  PickupScheduleApiRequest,
  PickupScheduleFormApiRequest
} from "@/data/payload/api/PickupScheduleApiRequest";
import {
  CourierVehicle,
  PickupSchedule,
  PickupScheduleList,
  RequestPickupScheduleList
} from "@/domain/entities/PickupSchedule";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { injectable } from "tsyringe";

@injectable()
export class PickupSchedulePresenter {
  private repository: PickupScheduleApiRepository;
  constructor(repo: PickupScheduleApiRepository) {
    this.repository = repo;
  }

  public getPickupScheduleList(
    params: RequestPickupScheduleList
  ): Promise<PickupScheduleList> {
    return this.repository.getPickupScheduleList(params);
  }
  public getDetailPickupSchedule(id: any): Promise<PickupSchedule> {
    return this.repository.getDetailPickupSchedule(id);
  }
  public getCourierVehicleList(search: string): Promise<CourierVehicle[]> {
    return this.repository.getCourierVehicleList(search);
  }
  public updateStatusPickupSchedule(
    payload: PickupScheduleApiRequest,
    id: any
  ): Promise<ResponsePayloadV2> {
    return this.repository.updateStatusPickupSchedule(payload, id);
  }

  public createPickupSchedule(
    payload: PickupScheduleFormApiRequest
  ): Promise<boolean> {
    return this.repository.createPickupSchedule(payload);
  }

  public updatePickupSchedule(
    payload: PickupScheduleFormApiRequest,
    id: number
  ): Promise<boolean> {
    return this.repository.updatePickupSchedule(payload, id);
  }
}
